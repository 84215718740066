import React, { useEffect, useCallback, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import { getCollectionDefinition } from 'api/collection_definitions';
import { handleErrorResponse } from 'utils/general';
import { getDynamicTableRows, updateDynamicTableRow } from 'api/sections';
// eslint-disable-next-line
import { getDynamicData } from 'utils/sections';
import MKBox from 'components/MaterialKit/MKBox';
import EditUserDetailsForm from './editUserForm';

const PurpleSunStaffEditUserDetails = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const user = auth?.user;
  const userId = searchParams.get('fpId');
  const [userDetails, setUserDetails] = useState(null);
  const [userDetailsCollectionDefinition, setUserDetailsCollectionDefinition] = useState({});

  const fetchCollectionDefinitions = useCallback(() => {
    return getCollectionDefinition('5970597b-affd-47c1-bdb4-7014dca4756b', { $expand: 'attributes' })
      .then(({ data }) => {
        setUserDetailsCollectionDefinition(data);
        return data;
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const fetchUserDetailsFromApi = useCallback(() => {
    if (!userId) return Promise.resolve();
    return getDynamicTableRows('5970597b-affd-47c1-bdb4-7014dca4756b', { 'created_by[eq]': userId })
      .then(({ data }) => {
        const humanReadableData = data.map((row) => {
          const { id } = row;
          return {
            id,
            data: getDynamicData(row, userDetailsCollectionDefinition.attributes),
          };
        });
        setUserDetails(humanReadableData);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth, userDetailsCollectionDefinition.attributes, userId]);

  useEffect(() => {
    if (!userDetailsCollectionDefinition.collection_definition_id) {
      fetchCollectionDefinitions();
      return;
    }
    fetchUserDetailsFromApi();
  }, [fetchCollectionDefinitions, fetchUserDetailsFromApi, userDetailsCollectionDefinition.collection_definition_id]);

  const onSubmit = (values) => {
    const updateBody = {
      '019173ce-dc45-7fd5-b353-924b98038325': values.display_name,
      '3b979c10-c17a-4bf6-be27-c63c43898604': values.job_title,
      '579eab61-0eed-4fea-815d-e985733a5b81': values.mobile,
      'b7bcf430-d03f-4c1f-921b-c941aa0d4be4': values.first_name,
      'fe4cb66e-6a16-42fa-a58f-2bae2b998d75': values.last_name,
      'e2e76a7f-bfb7-478b-b7e4-2d922ff8f05f': values.company_name,
    };
    const json_short_data = JSON.stringify(updateBody);
    const updateRowBody = {
      json_short_data,
    };
    return updateDynamicTableRow('5970597b-affd-47c1-bdb4-7014dca4756b', userDetails[0].id, updateRowBody)
      .then((response) => {
        if (response.status === 200) {
          navigate(`/staff/manage?fpId=${userId}`);
        }
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  };

  if (!userDetails) return null;
  return (
    <MKBox>
      <EditUserDetailsForm
        onAction={onSubmit}
        initialValues={userDetails[0].data || {}}
      />
    </MKBox>
  );
};

export default PurpleSunStaffEditUserDetails;
