import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Checkbox, Grid } from '@mui/material';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import InputField from 'components/InputField';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as Yup from 'yup';
import { useState } from 'react';
import { purpleSunColor } from 'sections/PurpleSunMenu';

const PSSignupForm = ({ onSignUp,
  initialValues,
  enableRememberMeOption,
  enableShowPassword,
  buttonColor,
  buttonTitle,
  usernamePlaceholder,
  passwordPlaceholder,
  usernameLabel,
  passwordLabel,
  showSignUp,
  showForgotPassword,
  textColor,
  forgotPasswordUrl,
  registerUrl,
  ...props }) => {
  const roleOptions = [{ value: 'allocator', label: 'Fund Manager' }, { value: 'investor', label: 'Investor' }];
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required('Name required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Required'),
    mobile: Yup.string()
      .matches(/^[0-9-]*$/, 'Invalid mobile number')
      .required('Mobile required'),
    password: Yup.string()
      .required('Password required'),
    repassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Reenter Password'),
    checkbox: Yup.bool()
      .oneOf([true], 'Must Accept Terms and Conditions'),
  });

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        password: '',
        repassword: '',
        checkbox: false,
        // add other fields here...
      }}
      validationSchema={validationSchema}
      onSubmit={onSignUp}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, touched }) => {
        if (!values.iam) { setFieldValue('iam', values.iam || roleOptions[0].value); }
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
            <Grid container spacing={1}>
              <Grid item container xs={12}>
                <Grid item xs={12}>
                  <MKBox mb={2}>
                    <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                      I am a/an
                    </MKTypography>
                    <InputField
                      name="iam"
                      label=""
                      value={values.iam || roleOptions[0].value}
                      onChange={handleChange('iam')}
                      onBlur={handleBlur('iam')}
                      type="select"
                      options={roleOptions}
                      fullWidth
                    />
                  </MKBox>
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={4}>
                <Grid item xs={6}>
                  <MKBox mb={2}>
                    <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                      First Name*
                    </MKTypography>
                    <MKInput
                      name="first_name"
                      label=""
                      placeholder="First Name"
                      value={values.first_name || ''}
                      onChange={handleChange('first_name')}
                      onBlur={handleBlur('first_name')}
                      fullWidth
                    />
                    {!!errors.first_name && touched.first_name && (
                    <MKTypography variant="caption" color="error">{errors.first_name}</MKTypography>
                    )}
                  </MKBox>
                </Grid>
                <Grid item xs={6}>
                  <MKBox mb={2}>
                    <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                      Last Name
                    </MKTypography>
                    <MKInput
                      name="last_name"
                      label=""
                      placeholder="Last Name"
                      value={values.last_name || ''}
                      onChange={handleChange('last_name')}
                      onBlur={handleBlur('last_name')}
                      fullWidth
                    />
                  </MKBox>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={4}>
                <Grid item xs={6}>
                  <MKBox mb={2}>
                    <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                      Company Name
                    </MKTypography>
                    <MKInput
                      name="company_name"
                      label=""
                      placeholder="Company Name"
                      value={values.company_name || ''}
                      onChange={handleChange('company_name')}
                      onBlur={handleBlur('company_name')}
                      fullWidth
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={6}>
                  <MKBox mb={2}>
                    <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                      Job Title
                    </MKTypography>
                    <MKInput
                      name="job_title"
                      label=""
                      placeholder="CEO"
                      value={values.job_title || ''}
                      onChange={handleChange('job_title')}
                      onBlur={handleBlur('job_title')}
                      fullWidth
                    />
                  </MKBox>
                </Grid>
              </Grid>
              <Grid item container spacing={4} xs={12}>
                <Grid item xs={6}>
                  <MKBox mb={2}>
                    <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                      Email*
                    </MKTypography>
                    <MKInput
                      name="email"
                      label=""
                      placeholder="name@email.com"
                      value={values.email || ''}
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                      fullWidth
                    />
                    {!!errors.email && touched.email && (
                    <MKTypography variant="caption" color="error">{errors.email}</MKTypography>
                    )}
                  </MKBox>
                </Grid>
                <Grid item xs={6}>
                  <MKBox mb={2}>
                    <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                      Mobile Number*
                    </MKTypography>
                    <MKInput
                      name="mobile"
                      label=""
                      placeholder="852-98765432"
                      value={values.mobile || ''}
                      onChange={handleChange('mobile')}
                      onBlur={handleBlur('mobile')}
                      fullWidth
                    />
                    {!!errors.mobile && touched.mobile && (
                    <MKTypography variant="caption" color="error">{errors.mobile}</MKTypography>
                    )}
                  </MKBox>
                </Grid>
              </Grid>
              <Grid item container spacing={4} xs={12}>
                <Grid item xs={12}>
                  <MKBox mb={2}>
                    <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                      Password*
                    </MKTypography>
                    <MKInput
                      name="password"
                      label=""
                      placeholder="Password"
                      type={showPassword ? 'text' : 'password'}
                      value={values.password}
                      onChange={handleChange('password')}
                      onBlur={handleBlur('password')}
                      fullWidth
                      InputProps={{
                        endAdornment: enableShowPassword ? (
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        ) : null,
                      }}
                    />
                  </MKBox>
                </Grid>
              </Grid>
              <Grid item container spacing={4} xs={12}>
                <Grid item xs={12}>
                  <MKBox mb={2}>
                    <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                      Re-enter password*
                    </MKTypography>
                    <MKInput
                      name="repassword"
                      label=""
                      placeholder="Re-enter password"
                      type={showPassword ? 'text' : 'password'}
                      value={values.repassword}
                      onChange={handleChange('repassword')}
                      onBlur={handleBlur('repassword')}
                      fullWidth
                      InputProps={{
                        endAdornment: enableShowPassword ? (
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        ) : null,
                      }}
                    />
                    {!!errors.repassword && touched.repassword && (
                    <MKTypography variant="caption" color="error">{errors.repassword}</MKTypography>
                    )}
                  </MKBox>
                </Grid>
              </Grid>
              <Grid item container spacing={4} xs={12}>
                <Grid item xs={12}>
                  <MKBox mb={2}>
                    <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                      <Checkbox
                        name="checkbox"
                        checked={values.checkbox}
                        onChange={handleChange('checkbox')}
                        onBlur={handleBlur('checkbox')}
                        color="primary"
                        sx={{ mr: 1 }}
                      />
                      I have read and agreed to the terms and conditions
                    </MKTypography>
                    {!!errors.checkbox && touched.checkbox && (
                    <MKTypography variant="caption" color="error">{errors.checkbox}</MKTypography>
                    )}
                  </MKBox>
                </Grid>
              </Grid>
              <Grid item container spacing={4} xs={12} justifyContent="end">
                <Grid item xs={4}>
                  <MKBox mt={4} mb={4}>
                    <Button type="submit" variant="gradient" color={purpleSunColor} size="large" fullWidth>
                      Sign up
                    </Button>
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

PSSignupForm.propTypes = {
  onSignUp: PropTypes.func,
  initialValues: PropTypes.object,
  enableShowPassword: PropTypes.bool,
  enableRememberMeOption: PropTypes.bool,
  showSignUp: PropTypes.bool,
  showForgotPassword: PropTypes.bool,
  usernamePlaceholder: PropTypes.string,
  usernameLabel: PropTypes.string,
  passwordPlaceholder: PropTypes.string,
  passwordLabel: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonTitle: PropTypes.string,
  textColor: PropTypes.string,
  forgotPasswordUrl: PropTypes.string,
  registerUrl: PropTypes.string,
};

PSSignupForm.defaultProps = {
  onSignUp: () => {},
  initialValues: {},
  enableShowPassword: true,
  enableRememberMeOption: false,
  showSignUp: true,
  showForgotPassword: false,
};

export default PSSignupForm;
