import React, { useCallback, useEffect, useState, useMemo } from 'react';
import BasicTable from 'sections/PurpleSunTable/table';
import { getData } from 'api/general';
import { useAuth } from 'contexts/auth';
import { handleErrorResponse } from 'utils/general';
import MKBox from 'components/MaterialKit/MKBox';
import { ArrowForwardIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { getDynamicTableRows } from 'api/sections';
import PropTypes from 'prop-types';

const PurpleSunStaffPanel = ({ userType, ...props }) => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const [rowsData, setRowsData] = useState(null);
  const [forms, setForms] = useState(null);
  const [contactRequests, setContactRequests] = useState(null);
  const fetchFromApi = useCallback(() => {
    return getData('/users', {}, 'bearer')
      .then(({ data }) => {
        setRowsData(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const rows_data = useMemo(() => (rowsData || []).map((row) => {
    if (userType === '') {
      return {
        id: row.email,
        name: row.display_name,
        username: row.username,
        email: row.email,
        role: row.role,
        active: row.active,
        onlineform: row.email,
        requested_contact: row.email,
      };
    } if (userType === '3') {
      if (row.role === 3) {
        return {
          id: row.email,
          name: row.display_name,
          username: row.username,
          email: row.email,
          role: row.role,
          active: row.active,
          onlineform: row.email,
          requested_contact: row.email,
        };
      }
    } else if (userType === '4') {
      if (row.role === 4) {
        return {
          id: row.email,
          name: row.display_name,
          username: row.username,
          email: row.email,
          role: row.role,
          active: row.active,
          onlineform: row.email,
          requested_contact: row.email,
        };
      }
    }
    return null;
  }).filter(Boolean), [rowsData, userType]);

  const fetchForms = useCallback(() => {
    getDynamicTableRows('556ee905-4a79-4e41-a3a5-e1b11decbc4b')
      .then(({ data }) => { setForms(data); })
      .catch((err) => { handleErrorResponse(err, setAuth); });
  }, [setAuth]);

  const fetchContactRequests = useCallback(() => {
    getDynamicTableRows('a18096ba-a8bf-4ced-a971-3fef7a9ec0c7')
      .then(({ data }) => { setContactRequests(data); })
      .catch((err) => { handleErrorResponse(err, setAuth); });
  }, [setAuth]);

  useEffect(() => {
    fetchFromApi();
    fetchForms();
    fetchContactRequests();
  }, [fetchContactRequests, fetchForms, fetchFromApi]);

  const requestedRole = (role) => {
    if (role === 1) {
      return 'Admin';
    }
    if (role === 2) {
      return 'Staff';
    }
    if (role === 3) {
      return 'Fund Manager';
    }
    if (role === 4) {
      return 'Investor';
    }
    if (role === 6) {
      return 'Other';
    }
  };
  const requestedActive = (active) => {
    if (active === true) {
      return 'Inactive';
    }
    return 'Active';
  };

  const requestedForm = (email) => {
    const formsForUser = forms?.map((form) => {
      const { json_short_data } = form;
      if (form.created_by === email) {
        const formStatus = JSON.parse(json_short_data)['f858ccc9-1d62-41bb-b0ea-3c1f53e9db85'];
        return formStatus;
      }
      return null;
    }).filter(Boolean);
    if (!formsForUser) return '/';
    if (formsForUser?.length === 0) return '/';
    if (formsForUser?.length === 1) return formsForUser.pop();
    return '/';
  };

  const requestedContact = (email) => {
    const usersContactRequests = contactRequests?.map((request) => {
      if (request.created_by === email) {
        return request;
      }
      return null;
    }).filter(Boolean);
    if (usersContactRequests?.length === 0) return '/';
    if (usersContactRequests?.length > 0) return usersContactRequests?.length;
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.7,
    },
    { field: 'id', headerName: 'Email', width: '50', flex: 0.7 },
    {
      field: 'role',
      headerName: 'Role',
      renderCell: (params) => (
        requestedRole(params.value)
      ),
      flex: 0.4,
    },
    {
      field: 'active',
      headerName: 'Status',
      renderCell: (params) => (
        requestedActive(params.value)
      ),
      flex: 0.25,
    },
    {
      field: 'onlineform',
      headerName: 'Online Form',
      renderCell: (params) => (
        requestedForm(params.value)
      ),
      sortable: false,
      flex: 0.6,
    },
    {
      field: 'requested_contact',
      headerName: 'Requested Contact',
      renderCell: (params) => (
        requestedContact(params.value)
      ),
      sortable: false,
      flex: 0.5,
    },
    { field: 'link',
      headerName: '',
      renderCell: (params) => {
        return (
          <ArrowForwardIos
            onClick={() => navigate(`/staff/manage/?fpId=${params.id}`)}
            sx={{ cursor: 'pointer' }}
          />
        );
      },
      flex: 0.15,
    },
  ];

  return (
    <MKBox mt={2}>
      <BasicTable
        rows={rows_data}
        columns={columns}
        navigateOnClick
        navigatePath="/staff/manage/?fpId="
        pointer="username"
      />
    </MKBox>
  );
};

PurpleSunStaffPanel.propTypes = {
  userType: PropTypes.string,
};

export default PurpleSunStaffPanel;
