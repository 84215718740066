import React from 'react';
// eslint-disable-next-line
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';

const BasicTable = ({ rows, columns, navigateOnClick, navigatePath, pointer }) => {
  const navigate = useNavigate();
  if (rows.length === 0) return null;
  const handleEvent = (
    params,
    event,
    details,
  ) => {
    console.log(params.row[pointer]);
    console.log(`${navigatePath}`);
    navigate(`${navigatePath}${params.row[pointer]}`);
  };
  return (
    <Box sx={{ height: 'auto', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 20, 50]}
        disableRowSelectionOnClick
        onRowClick={navigateOnClick ? handleEvent : undefined}
      />
    </Box>
  );
};

BasicTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  navigateOnClick: PropTypes.bool,
  navigatePath: PropTypes.string,
  pointer: PropTypes.string,
};

export default BasicTable;
