import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormControl, Grid } from '@mui/material';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import InputField from 'components/InputField';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { purpleSunColor } from 'sections/PurpleSunMenu';

const EditUserDetailsForm = ({ onAction,
  initialValues,
  ...props }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const username = searchParams.get('fpId');
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onAction}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, dirty, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit} sx={{}}>
            <FormControl sx={{
              width: '100%' }}
            >
              <MKBox display="flex" justifyContent="space-between" mt={2}>
                <MKBox
                  display="flex"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => {
                      navigate(-1);
                    }}
                    sx={{ mr: 2 }}
                  >
                    <MKTypography color="white" fontSize="14px" fontWeight="bold">
                      Back
                    </MKTypography>
                  </Button>
                  <MKTypography variant="body1" fontSize="16px" fontWeight="bold">
                    {`User Mgt List > Edit > ${username} ` }
                  </MKTypography>
                </MKBox>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  disabled={!dirty}
                  color={purpleSunColor}
                >
                  <MKTypography color="white" fontSize="14px" fontWeight="bold">
                    Update
                  </MKTypography>
                </Button>
              </MKBox>
              <Grid mt={1} container spacing={1}>
                <Grid item container xs={6} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox>
                      <MKTypography variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        First Name
                      </MKTypography>
                      <InputField
                        name="first_name"
                        label=""
                        placeholder="First Name"
                        value={values.first_name}
                        type="text"
                        onChange={handleChange('first_name')}
                        onBlur={handleBlur('first_name')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid item container xs={6} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox>
                      <MKTypography variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Last Name
                      </MKTypography>
                      <InputField
                        name="last_name"
                        label=""
                        placeholder="Last Name"
                        value={values.last_name}
                        type="text"
                        onChange={handleChange('last_name')}
                        onBlur={handleBlur('last_name')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox>
                      <MKTypography variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Display Name
                      </MKTypography>
                      <InputField
                        name="display_name"
                        label=""
                        placeholder="Display Name"
                        value={values.display_name}
                        type="text"
                        onChange={handleChange('display_name')}
                        onBlur={handleBlur('display_name')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox>
                      <MKTypography variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Company Name
                      </MKTypography>
                      <InputField
                        name="company_name"
                        label=""
                        placeholder="Company Name"
                        value={values.company_name}
                        type="text"
                        onChange={handleChange('company_name')}
                        onBlur={handleBlur('company_name')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox>
                      <MKTypography variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Job Title
                      </MKTypography>
                      <InputField
                        name="job_title"
                        label=""
                        placeholder="Job Title"
                        value={values.job_title}
                        type="text"
                        onChange={handleChange('job_title')}
                        onBlur={handleBlur('job_title')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox>
                      <MKTypography variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Mobile
                      </MKTypography>
                      <InputField
                        name="mobile"
                        label=""
                        placeholder="85212345678"
                        value={values.mobile}
                        type="text"
                        onChange={handleChange('mobile')}
                        onBlur={handleBlur('mobile')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                </Grid>
              </Grid>
              {!!errors.form && (
              <MKTypography variant="caption" color="error">{errors.form}</MKTypography>
              )}
            </FormControl>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditUserDetailsForm.propTypes = {
  onAction: PropTypes.func,
  initialValues: PropTypes.object,
};

EditUserDetailsForm.defaultProps = {
  onAction: () => {},
  initialValues: {},
};

export default EditUserDetailsForm;
