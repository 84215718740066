import React, { useState, useMemo, useEffect, useCallback } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { PropTypes } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import { Slider } from '@mui/material';
import { getCollectionDefinition } from 'api/collection_definitions';
import { handleErrorResponse, parseJSON } from 'utils/general';
import { purpleSunColor } from 'sections/PurpleSunMenu';
import { getDynamicTableRows } from 'api/sections';
import { useAuth } from 'contexts/auth';
// eslint-disable-next-line
import { getDynamicData } from 'utils/sections';
import { aum_options, hocloc_options, sector_options, tc_rr_options } from '../FundManagerForm/options';
import FilterOpenButton from './filterButton';
import BasicTable from './table';

const requestedLabel = (status) => {
  if (status === 0) {
    return null;
  }
  if (status === 1) {
    return (
      <MKBox display="flex" alignItems="center" width="100%" height="100%">
        <MKTypography px={1} variant="subtitle2" color="white" fontWeight="bold" sx={{ backgroundColor: '#989898', borderRadius: '8px' }}>
          Requested
        </MKTypography>
      </MKBox>
    );
  }
  return (
    <MKBox display="flex" alignItems="center" width="100%" height="100%">
      <MKTypography px={1} variant="subtitle2" color="white" fontWeight="bold" sx={{ backgroundColor: purpleSunColor, borderRadius: '8px' }}>
        Accepted
      </MKTypography>
    </MKBox>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DrawerList = (props) => {
  const getLabel = (value, options) => {
    const option = options.find((opt) => opt.value === value);
    return option?.label;
  };
  const { regions, setRegions, regionOptions } = props;
  const regionKeysArray = regionOptions.map((r) => { return r.value; });
  const { aum, setAum, aumOptions } = props;
  const aumKeysArray = aumOptions.map((r) => { return r.value; });
  const { sector, setSector, sectorOptions } = props;
  const sectorKeysArray = sectorOptions.map((r) => { return r.value; });
  const { revenueRange, setRevenueRange, revenueRangeOptions } = props;
  const revenueRangeKeysArray = revenueRangeOptions.map((r) => { return r.value; });

  return (
    <Box p={4} sx={{ width: '450px' }} role="presentation">
      <List>
        <MKBox>
          <MKTypography variant="h3" mb={2} fontSize="20px" color="black">
            Regions
          </MKTypography>
          <FormControl sx={{ width: '100%' }}>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={regions}
              displayEmpty
              onChange={setRegions}
              renderValue={(slctd) => {
                if (slctd.length === 0) return ('All');
                const array = slctd.map((selectedOption) => {
                  const label = getLabel(selectedOption, regionOptions);
                  return label;
                }).join(', ');
                return array;
              }}
              MenuProps={MenuProps}
            >
              {regionKeysArray.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={regions.indexOf(name) > -1} />
                  <ListItemText primary={getLabel(name, regionOptions)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MKBox>
        <Divider />
        <MKBox>
          <MKTypography variant="h3" mb={2} fontSize="20px" color="black">
            AUM in total
          </MKTypography>
          <FormControl sx={{ width: '100%' }}>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              displayEmpty
              value={aum}
              onChange={setAum}
              renderValue={(slctd) => {
                if (slctd.length === 0) return ('All');
                const array = slctd.map((selectedOption) => {
                  const label = getLabel(selectedOption, aumOptions);
                  return label;
                }).join(', ');
                return array;
              }}
              MenuProps={MenuProps}
            >
              {aumKeysArray.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={aum.indexOf(name) > -1} />
                  <ListItemText primary={getLabel(name, aumOptions)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MKBox>
        <Divider />
        <MKBox>
          <MKTypography variant="h3" mb={2} fontSize="20px" color="black">
            Sector
          </MKTypography>
          <FormControl sx={{ width: '100%' }}>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              displayEmpty
              value={sector}
              onChange={setSector}
              renderValue={(slctd) => {
                if (slctd.length === 0) return ('All');
                const array = slctd.map((selectedOption) => {
                  const label = getLabel(selectedOption, sectorOptions);
                  return label;
                }).join(', ');
                return array;
              }}
              MenuProps={MenuProps}
            >
              {sectorKeysArray.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={sector.indexOf(name) > -1} />
                  <ListItemText primary={getLabel(name, sectorOptions)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MKBox>
        <Divider />
        <MKBox>
          <MKTypography variant="h3" mb={2} fontSize="20px" color="black">
            Revenue Range
          </MKTypography>
          <FormControl sx={{ width: '100%' }}>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              displayEmpty
              value={revenueRange}
              onChange={setRevenueRange}
              renderValue={(slctd) => {
                if (slctd.length === 0) return ('All');
                const array = slctd.map((selectedOption) => {
                  const label = getLabel(selectedOption, tc_rr_options);
                  return label;
                }).join(', ');
                return array;
              }}
              MenuProps={MenuProps}
            >
              {revenueRangeKeysArray.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={revenueRange.indexOf(name) > -1} />
                  <ListItemText primary={getLabel(name, tc_rr_options)} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MKBox>
      </List>
    </Box>
  );
};

DrawerList.propTypes = {
  regions: PropTypes.arrayOf(PropTypes.array).isRequired,
  setRegions: PropTypes.func.isRequired,
  regionOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  aum: PropTypes.arrayOf(PropTypes.array).isRequired,
  setAum: PropTypes.func.isRequired,
  aumOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  sector: PropTypes.arrayOf(PropTypes.array).isRequired,
  setSector: PropTypes.func.isRequired,
  sectorOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  revenueRange: PropTypes.arrayOf(PropTypes.array).isRequired,
  setRevenueRange: PropTypes.func.isRequired,
  revenueRangeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const getLabel = (value, options) => {
  const option = options.find((opt) => opt.value === value);
  return option?.label;
};

const createData = (id, revenue, sectors, name, hql, caum, link, regions) => {
  return { id, revenue, sectors, name, hql, caum, link, regions };
};

const PurpleSunTablePage = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [collectionDefinition, setCollectionDefinition] = useState({});
  const [requests, setRequests] = useState([]);
  const [open, setOpen] = useState(false);
  const user = auth?.user;
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const [dataRows, setDataRows] = useState([]);

  const [aumFilter, setAumFilter] = useState([]);
  const handleAumChange = (event) => {
    const {
      target: { value },
    } = event;
    setAumFilter(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const [regions, setRegions] = useState([]);
  const handleRegionChange = (event) => {
    const {
      target: { value },
    } = event;
    setRegions(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const [sector, setSectors] = useState([]);
  const handleSectorChange = (event) => {
    const {
      target: { value },
    } = event;
    setSectors(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const [revenueRange, setRevenueRange] = useState([]);
  const handleRevenueRangeChange = (event) => {
    const {
      target: { value },
    } = event;
    setRevenueRange(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'hql',
      headerName: 'HQ Geograpic location',
      renderCell: (params) => (
        getLabel(params.value, hocloc_options)
      ),
      flex: 1,
    },
    {
      field: 'caum',
      headerName: 'Current AUM in total',
      renderCell: (params) => (
        getLabel(params.value, aum_options)
      ),
      flex: 1,
    },
    {
      field: 'status',
      headerName: '',
      renderCell: (params) => (
        requestedLabel(params.value)
      ),
      flex: 0.7,
    },
    { field: 'link',
      headerName: '',
      renderCell: (params) => {
        return (
          <ArrowForwardIos
            onClick={() => navigate(`/investor/view/fund_manager?fpId=${params.row.name}`)}
            sx={{ cursor: 'pointer' }}
          />
        );
      },
      flex: 0.15,
    },
  ];

  const fetchFPSfromApi = useCallback(() => {
    return getDynamicTableRows(collectionDefinition.collection_definition_id, {})
      .then(({ data }) => {
        const rs = data.map((d) => {
          const dynamicData = getDynamicData(d, collectionDefinition.attributes);
          return {
            id: d.id,
            fp: dynamicData.display_name || d.created_by,
            ...dynamicData,
          };
        }).filter((s) => s.status === 'Approved');
        setDataRows(rs);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [collectionDefinition.attributes, collectionDefinition.collection_definition_id, setAuth]);

  const getCollectionDefinitionFromApi = useCallback(
    () => {
      const id = '556ee905-4a79-4e41-a3a5-e1b11decbc4b';
      return getCollectionDefinition(id, {
        $expand: 'attributes',
      })
        .then(({ data }) => {
          setCollectionDefinition(data);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    },
    [setAuth],
  );

  const getRequestsFromApi = useCallback(() => {
    return getDynamicTableRows('a18096ba-a8bf-4ced-a971-3fef7a9ec0c7', { 'created_by[eq]': auth?.user?.username })
      .then(({ data }) => {
        const humanReadableData = data.map((row) => {
          const dynamic = JSON.parse(row.json_short_data);
          const map = {
            fundmanager: dynamic['dbd919ef-603f-463d-89ee-3849b71449f3'],
            status: dynamic['49e04031-9c8a-4eb6-9ccf-dead390afd58'],
          };
          return map;
        });
        setRequests(humanReadableData);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [auth?.user?.username, setAuth]);

  useEffect(() => {
    if (user.role.role_id === 3) {
      navigate('/fund_manager/forms');
    }
    if (collectionDefinition.collection_definition_id) {
      fetchFPSfromApi();
      getRequestsFromApi();
      return;
    }
    getCollectionDefinitionFromApi();
  }, [collectionDefinition, getRequestsFromApi, fetchFPSfromApi, getCollectionDefinitionFromApi, user.role.role_id, navigate]);

  const rows = useMemo(() => {
    const mappedData = dataRows.map((d) => {
      return createData(d.id, d.trr, [d.ts_primary, d.ts_secondary, d.ts_third], d.fp, d.hgl, d.current_aum, d.link, [d.tgl_primary, d.tgl_secondary, d.tgl_third]);
    });
    return mappedData;
  }, [dataRows]);

  const preparedRows = useMemo(() => rows.map((row) => {
    const mutableRow = row;
    const rowRegions = row.regions;
    const rowAum = row.caum;
    const rowSectors = row.sectors;
    const rowTrr = row.revenue;
    if (regions.length !== 0) {
      const hasMatch = regions.some((r) => rowRegions.includes(r));
      if (!hasMatch) { return null; }
    }
    if (aumFilter.length !== 0) {
      if (!aumFilter.includes(rowAum)) { return null; }
    }
    if (sector.length !== 0) {
      const hasMatch = sector.some((r) => rowSectors.includes(r));
      if (!hasMatch) { return null; }
    }
    if (revenueRange.length !== 0) {
      if (!revenueRange.includes(rowTrr)) { return null; }
    }
    requests.map((r) => {
      if (r.fundmanager === row.name) {
        if (r.status === 'true') {
          mutableRow.status = 2;
        } else {
          mutableRow.status = 1;
        }
      }
      return null;
    });
    if (mutableRow.status === undefined) {
      mutableRow.status = 0;
    }
    return mutableRow;
  }).filter(Boolean), [rows, regions, aumFilter, sector, revenueRange, requests]);

  return (
    <MKBox display="flex" flexDirection="column">
      <Stack my={2} direction="row" spacing={4}>
        <FilterOpenButton label="Region" count={regions.length || 0} onClick={toggleDrawer(true)} />
        <FilterOpenButton label="Aum in Total" count={aumFilter.length || 0} onClick={toggleDrawer(true)} />
        <FilterOpenButton label="Sector" count={sector.length || 0} onClick={toggleDrawer(true)} />
        <FilterOpenButton label="Revenue Range" count={revenueRange.length || 0} onClick={toggleDrawer(true)} />
      </Stack>
      <BasicTable columns={columns} rows={preparedRows} navigateOnClick navigatePath="/investor/view/fund_manager?fpId=" pointer="name" />
      <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
        <DrawerList
          regions={regions}
          setRegions={handleRegionChange}
          regionOptions={hocloc_options}
          aum={aumFilter}
          setAum={handleAumChange}
          aumOptions={aum_options}
          sector={sector}
          setSector={handleSectorChange}
          sectorOptions={sector_options}
          revenueRange={revenueRange}
          setRevenueRange={handleRevenueRangeChange}
          revenueRangeOptions={tc_rr_options}
        />
      </Drawer>
    </MKBox>
  );
};

export default PurpleSunTablePage;
