import React, { useCallback, useState, useEffect, useMemo } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { Stack } from '@mui/material';
import { getDynamicTableRow, getDynamicTableRows, updateDynamicTableRow } from 'api/sections';
// eslint-disable-next-line import/no-cycle
import { getDynamicData } from 'utils/sections';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { datetimeFormatter } from 'utils/datetime';
import { getCollectionDefinition } from 'api/collection_definitions';
import Button from 'components/Button';
import BasicTable from 'sections/PurpleSunTable/table';
import { purpleSunColor } from 'sections/PurpleSunMenu';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';

const PendingContactRequestsTable = ({ ...props }) => {
  const [contactRequests, setContactRequests] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [collectionDefinition, setCollectionDefinition] = useState({});
  const [userDetailsCollectionDefinition, setUserDetailsCollectionDefinition] = useState({});
  const user = auth?.user;

  const fetchCollectionDefinitions = useCallback(() => {
    return Promise.all([
      getCollectionDefinition('a18096ba-a8bf-4ced-a971-3fef7a9ec0c7', { $expand: 'attributes' })
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        }),
      getCollectionDefinition('5970597b-affd-47c1-bdb4-7014dca4756b', { $expand: 'attributes' })
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        })])
      .then(([target, userdetails]) => {
        setCollectionDefinition(target);
        setUserDetailsCollectionDefinition(userdetails);
      });
  }, [setAuth]);

  const fetchUserDetailsFromApi = useCallback(() => {
    if (!user?.username) return Promise.resolve();
    return getDynamicTableRows('5970597b-affd-47c1-bdb4-7014dca4756b', { 'created_by[eq]': user?.username })
      .then(({ data }) => {
        const humanReadableData = data.map((row) => {
          return getDynamicData(row, userDetailsCollectionDefinition.attributes);
        });
        setUserDetails(humanReadableData);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth, user?.username, userDetailsCollectionDefinition.attributes]);

  const fetchContactRequestsFromApi = useCallback(() => {
    if (!user?.username) return Promise.resolve();
    return getDynamicTableRows('a18096ba-a8bf-4ced-a971-3fef7a9ec0c7')
      .then(({ data }) => {
        const humanReadableData = data.map((row) => {
          const dynamicData = getDynamicData(row, collectionDefinition.attributes);
          dynamicData.id = row.id;
          dynamicData.createddate = row.createddate;
          return dynamicData;
        }).filter((row) => { return row.staff_approval === true && row.staff_rejected === false && row.fund_manager === user.email; });
        setContactRequests(humanReadableData);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [collectionDefinition.attributes, setAuth, user?.email, user?.username]);

  const proceedApprove = useCallback((row, type) => {
    getDynamicTableRow('a18096ba-a8bf-4ced-a971-3fef7a9ec0c7', row.id)
      .then(({ data }) => {
        return JSON.parse(data.json_short_data);
      })
      .then((jsonShortData) => {
        const updateBody = {
          ...jsonShortData,
          '49e04031-9c8a-4eb6-9ccf-dead390afd58': type === 'approve',
        };
        return updateDynamicTableRow('a18096ba-a8bf-4ced-a971-3fef7a9ec0c7', row.id, {
          json_short_data: JSON.stringify(updateBody),
        }).then((response) => {
          if (response.status === 200) {
            fetchContactRequestsFromApi();
          }
        });
      });
  }, [fetchContactRequestsFromApi]);

  const approveContactRequest = useCallback((row, type) => {
    if (type === 'approve') {
    // Open the dialog
      setOpen(true);
    } else {
      proceedApprove(row, type);
    }
  }, [proceedApprove]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = (row, type) => {
    setOpen(false);
    console.log('==WIP==\nPretend fee charged... To be implemented.');
    proceedApprove(row, type);
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: '50', flex: 0.15 },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        params.value ? 'Approved' : 'Pending'
      ),
    },
    {
      field: 'createddate',
      headerName: 'Submitted Date',
      renderCell: (params) => (
        datetimeFormatter(params.value)
      ),
      flex: 0.5,
    },
    { field: 'link',
      headerName: '',
      renderCell: (params) => {
        if (params.row.fund_manager_approval === true) {
          return (
            <MKBox display="flex" justifyContent="center" alignItems="center" alignContent="center" height="100%">
              <Button
                variant="contained"
                color={purpleSunColor}
                size="small"
                onClick={() => {
                  navigate(`/fund_manager/allocator?id=${params.row.email}`);
                }}
              >
                <MKTypography fontSize="12px" fontWeight="bold" color="white">
                  View Allocator Info
                </MKTypography>
              </Button>
            </MKBox>
          );
        }
        return (
          <MKBox display="flex" justifyContent="center" alignItems="center" alignContent="center" height="100%">
            <Stack direction="row" spacing={1}>
              <Button variant="contained" color={purpleSunColor} size="small" onClick={() => { approveContactRequest(params.row, 'approve'); }}>
                <MKTypography fontSize="12px" fontWeight="bold" color="white">
                  Accept
                </MKTypography>
              </Button>
              <Button variant="outlined" color="gray" size="small" onClick={() => { approveContactRequest(params.row, 'reject'); }}>
                <MKTypography fontSize="12px" fontWeight="bold" color="gray">
                  Ignore
                </MKTypography>
              </Button>
            </Stack>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" sx={{ paddingX: 2 }}>Reminder: </DialogTitle>
              <DialogContent sx={{ paddingX: 2 }}>
                <DialogContentText id="alert-dialog-description" sx={{ fontWeight: 'regular' }}>
                  Success fee will be charged once Allocator committed.
                </DialogContentText>
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'space-between', paddingX: 2 }}>
                <Button variant="outlined" onClick={handleClose} color="gray" size="small">
                  <MKTypography variant="body1" color="gray">
                    Cancel
                  </MKTypography>
                </Button>
                <Button onClick={() => handleApprove(params.row, 'approve')} size="small" color={purpleSunColor} autoFocus>
                  <MKTypography variant="body1" color="white" fontWeight="bold">
                    Approve
                  </MKTypography>
                </Button>
              </DialogActions>
            </Dialog>
          </MKBox>
        );
      },
      flex: 1,
    },
  ];

  useEffect(() => {
    if (user.role.role_id === 4) {
      navigate('/investor');
    }
    if (!collectionDefinition.collection_definition_id || !userDetailsCollectionDefinition.collection_definition_id) {
      fetchCollectionDefinitions();
    }
    fetchUserDetailsFromApi();
    fetchContactRequestsFromApi();
  }, [collectionDefinition.collection_definition_id, fetchCollectionDefinitions, fetchContactRequestsFromApi, fetchUserDetailsFromApi, navigate, user.role.role_id, userDetailsCollectionDefinition.collection_definition_id]);

  const rows = useMemo(() => {
    return contactRequests?.map((row) => {
      return {
        id: row.id,
        name: row.investor_name,
        email: row.investor,
        createddate: row.createddate,
        fund_manager_approval: row.fund_manager_approval,
        staff_approval: row.staff_approval,
        staff_rejected: row.staff_rejected,
        status: row.fund_manager_approval,
      };
    });
  }, [contactRequests]);

  return (
    <MKBox mt={2} display="flex" flexDirection="column">
      <BasicTable
        rows={rows || []}
        columns={columns || []}
      />
      {rows && rows.length === 0 && (
        <MKBox display="flex" flexDirection="row" alignItems="center">
          <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black" mr={2}>
            No pending contact requests
          </MKTypography>
        </MKBox>
      )}
    </MKBox>
  );
};

export default PendingContactRequestsTable;
