import React, { useState } from 'react';
import qs from 'qs';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';
import Image from 'components/Image';
import { useMenu } from 'contexts/menu';
import { useAuth } from 'contexts/auth';
import { logout } from 'api/auth';
import MenuModal from './menuModal';

export const purpleSunColor = '#8167e8';

const PurpleSunHeaderNew = ({ logo_url, background_color, display_searchbox, editable, onPressEdit, ...props }) => {
  const { menu } = useMenu();
  const { menu_node } = menu || {};
  const { menu_items } = menu_node || {};
  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => { setModalOpen(true); };
  const closeModal = () => { setModalOpen(false); };
  const tryOpenModal = () => {
    if (modalOpen) {
      closeModal();
    } else {
      openModal();
    }
  };

  return (
    <MKBox px={{ xs: 0, md: 1 }} py={{ xs: 1, md: 2 }} bgColor={background_color} {...props}>
      <Container>
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          <MKBox display="flex" alignItems="center">
            <Image
              src={logo_url}
              height="2rem"
              mr={2}
            />
          </MKBox>
          <MKBox
            position="relative"
            display={{ xs: 'flex' }}
            lineHeight={0}
            pl={1.5}
            sx={{ cursor: 'pointer' }}
            onClick={() => tryOpenModal()}
          >
            <Icon fontSize="default" color="dark">
              {modalOpen ? 'close' : 'menu'}
            </Icon>
            {modalOpen && (
            <MKBox
              position="absolute"
              top="100%"
              right="0"
              zIndex="1"
            >
              <MenuModal menuItems={menu_items} isOpen={modalOpen} onClose={closeModal} />
            </MKBox>
            )}
          </MKBox>
        </MKBox>
      </Container>
    </MKBox>
  );
};

PurpleSunHeaderNew.propTypes = {
  logo_url: PropTypes.string,
  background_color: PropTypes.string,
  display_searchbox: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default PurpleSunHeaderNew;
