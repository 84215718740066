import React from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { logout } from 'api/auth';
import { useAuth } from 'contexts/auth';
import { useNavigate } from 'react-router-dom';
import { purpleSunColor } from 'sections/PurpleSunMenu';

const PurpleSunUserWelcome = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const { user } = auth;
  if (!user) {
    return null;
  }
  return (
    <MKBox
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <MKTypography variant="h1" fontSize="16px" color="black">
        {`Welcome ${user.username},`}
      </MKTypography>
      <Button variant="contained" color={purpleSunColor} onClick={() => { logout(); navigate('/login'); }}>
        <MKTypography color="white" fontSize="14px" fontWeight="bold">
          Log out
        </MKTypography>
      </Button>
    </MKBox>
  );
};

export default PurpleSunUserWelcome;
