import get from 'lodash/get';
import { initialAuthState } from 'contexts/auth';
import { setStoredAuth } from 'api';

let handlingForceLogout = false;

export const isUuid = (v) => {
  const uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return uuidRegex.test(v);
};

export const parseJSON = (str, defaultValue) => {
  try {
    return JSON.parse(str || JSON.stringify(defaultValue));
  } catch (err) {
    return defaultValue || null;
  }
};

export const getErrorMessage = (error) => {
  const message = get(error, 'response.data.error_description')
    || get(error, 'response.data.message')
    || get(error, 'response.data.error')
    || JSON.stringify(get(error, 'response.data'))
    || get(error, 'message');
  return message;
};

export const handleErrorResponse = (err, setAuth, errMsg) => {
  if (get(err, 'response.status') === 401) {
    if (!handlingForceLogout) {
      handlingForceLogout = true;
      // alert('You have Been Logged Out');
      setAuth({ ...initialAuthState, required: true });
      setStoredAuth(initialAuthState);
      handlingForceLogout = false;
    }
  } else {
    alert(errMsg || getErrorMessage(err));
  }
};
