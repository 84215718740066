import React, { useCallback, useState, useEffect, useMemo } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { Divider, Grid, Stack } from '@mui/material';
import { getDynamicTableRow, getDynamicTableRows, updateDynamicTableRow } from 'api/sections';
// eslint-disable-next-line import/no-cycle
import { getDynamicData } from 'utils/sections';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { datetimeFormatter } from 'utils/datetime';
import { PropTypes } from 'prop-types';
import { getCollectionDefinition } from 'api/collection_definitions';
import Button from 'components/Button';
import BasicTable from 'sections/PurpleSunTable/table';
import { purpleSunColor } from 'sections/PurpleSunMenu';
import { useNavigate } from 'react-router-dom';

const StaffInvestorMgtPage = ({ user, ...props }) => {
  const [contactRequests, setContactRequests] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [collectionDefinition, setCollectionDefinition] = useState({});
  const [userDetailsCollectionDefinition, setUserDetailsCollectionDefinition] = useState({});

  const fetchCollectionDefinitions = useCallback(() => {
    return Promise.all([
      getCollectionDefinition('a18096ba-a8bf-4ced-a971-3fef7a9ec0c7', { $expand: 'attributes' })
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        }),
      getCollectionDefinition('5970597b-affd-47c1-bdb4-7014dca4756b', { $expand: 'attributes' })
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        })])
      .then(([target, userdetails]) => {
        setCollectionDefinition(target);
        setUserDetailsCollectionDefinition(userdetails);
      });
  }, [setAuth]);

  const fetchUserDetailsFromApi = useCallback(() => {
    if (!user?.username) return Promise.resolve();
    return getDynamicTableRows('5970597b-affd-47c1-bdb4-7014dca4756b', { 'created_by[eq]': user?.username })
      .then(({ data }) => {
        const humanReadableData = data.map((row) => {
          return getDynamicData(row, userDetailsCollectionDefinition.attributes);
        });
        setUserDetails(humanReadableData);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth, user?.username, userDetailsCollectionDefinition.attributes]);

  const fetchFPdataFromApi = useCallback(() => {
    if (!user?.username) return Promise.resolve();
    return getDynamicTableRows('a18096ba-a8bf-4ced-a971-3fef7a9ec0c7', { 'created_by[eq]': user?.username })
      .then(({ data }) => {
        const humanReadableData = data.map((row) => {
          const dynamicData = getDynamicData(row, collectionDefinition.attributes);
          dynamicData.id = row.id;
          dynamicData.createddate = row.createddate;
          return dynamicData;
        });
        setContactRequests(humanReadableData);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [collectionDefinition?.attributes, setAuth, user?.username]);

  const approveContactRequest = useCallback((row, type) => {
    const shortData = contactRequests.find((contactRequest) => { return contactRequest.id === row.id; });
    getDynamicTableRow('a18096ba-a8bf-4ced-a971-3fef7a9ec0c7', row.id)
      .then(({ data }) => {
        return JSON.parse(data.json_short_data);
      })
      .then((jsonShortData) => {
        const updateBody = {
          ...jsonShortData,
          '9eb8999d-a073-48d1-86b9-c0be33e9e43f': type === 'approve',
          '096d3a31-e12d-4f27-90d1-fa7939400c27': type === 'reject',
        };
        return updateDynamicTableRow('a18096ba-a8bf-4ced-a971-3fef7a9ec0c7', row.id, {
          json_short_data: JSON.stringify(updateBody),
        }).then((response) => {
          fetchFPdataFromApi();
        });
      });
  }, [contactRequests, fetchFPdataFromApi]);

  const columns = [
    { field: 'id', headerName: 'ID', width: '50', flex: 0.15 },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'createddate',
      headerName: 'Request sent Date',
      renderCell: (params) => (
        datetimeFormatter(params.value)
      ),
      flex: 0.5,
    },
    { field: 'link',
      headerName: '',
      renderCell: (params) => {
        if (params.row.staff_rejected === true) {
          return (
            <MKBox display="flex" justifyContent="center" alignItems="center" alignContent="center" height="100%">
              <Button variant="contained" color={purpleSunColor} size="small">
                <MKTypography fontSize="12px" fontWeight="bold" color="white">
                  Rejected
                </MKTypography>
              </Button>
            </MKBox>
          );
        }
        if (params.row.staff_approval === true) {
          return (
            <MKBox display="flex" justifyContent="center" alignItems="center" alignContent="center" height="100%">
              <Button variant="contained" color={purpleSunColor} size="small">
                <MKTypography fontSize="12px" fontWeight="bold" color="white">
                  Approved
                </MKTypography>
              </Button>
            </MKBox>
          );
        }
        return (
          <MKBox display="flex" justifyContent="center" alignItems="center" alignContent="center" height="100%">
            <Stack direction="row" spacing={1}>
              <Button variant="outlined" color="gray" size="small" onClick={() => { approveContactRequest(params.row, 'reject'); }}>
                <MKTypography fontSize="12px" fontWeight="bold" color="gray">
                  Reject
                </MKTypography>
              </Button>
              <Button variant="contained" color={purpleSunColor} size="small" onClick={() => { approveContactRequest(params.row, 'approve'); }}>
                <MKTypography fontSize="12px" fontWeight="bold" color="white">
                  Approve
                </MKTypography>
              </Button>
            </Stack>
          </MKBox>
        );
      },
      flex: 1,
    },
  ];

  useEffect(() => {
    if (!collectionDefinition.collection_definition_id || !userDetailsCollectionDefinition.collection_definition_id) {
      fetchCollectionDefinitions();
    }
    fetchUserDetailsFromApi();
    fetchFPdataFromApi();
  }, [collectionDefinition.collection_definition_id, fetchCollectionDefinitions, fetchFPdataFromApi, fetchUserDetailsFromApi, userDetailsCollectionDefinition.collection_definition_id]);

  const userInfo = useMemo(() => userDetails?.[0], [userDetails]);
  const rows = useMemo(() => {
    return contactRequests?.map((row) => {
      return {
        id: row.id,
        name: row.fund_manager_name,
        email: row.fund_manager,
        createddate: row.createddate,
        fund_manager_approval: row.fund_manager_approval,
        staff_approval: row.staff_approval,
        staff_rejected: row.staff_rejected,
        link: 'Two buttons here',
      };
    });
  }, [contactRequests]);

  return (
    <MKBox mt={2} display="flex" flexDirection="column">
      <MKTypography variant="h1" fontSize="24px" color="black">
        {user.display_name}
        &apos;s Information
      </MKTypography>
      <MKBox
        mt={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="left"
      >
        <Grid container spacing={1} alignItems="center" justifyContent="center">
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Tel:&nbsp;
              {userInfo?.mobile}
            </MKTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Email:&nbsp;
              {userInfo?.email}
            </MKTypography>
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} container item justifyContent="end">
            <Button variant="contained" color="#00A55E" size="small" onClick={() => { navigate(`/staff/manage/edit?fpId=${userInfo?.email}`); }}>
              <MKTypography color="white" fontSize="14px" fontWeight="bold">
                Edit User Info
              </MKTypography>
            </Button>
          </Grid>
        </Grid>
      </MKBox>
      <Divider />
      <MKBox
        display="flex"
        flexDirection="column"
        alignItems="left"
      >
        <MKBox display="flex" flexDirection="column" justifyContent="flex-start">
          <MKTypography variant="h4" fontWeight="bold" fontSize="20px" color="black">
            {`${userInfo?.first_name} ${userInfo?.last_name} Pending Contact Request to Fund Managers`}
          </MKTypography>
          <MKBox mt={2}>
            <BasicTable
              rows={rows || []}
              columns={columns || []}
            />
          </MKBox>
        </MKBox>
      </MKBox>
    </MKBox>
  );
};

StaffInvestorMgtPage.propTypes = {
  user: PropTypes.object,
};

export default StaffInvestorMgtPage;
