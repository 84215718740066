import React, { useState } from 'react';
import qs from 'qs';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';
import Image from 'components/Image';
import { useMenu } from 'contexts/menu';
import { useAuth } from 'contexts/auth';
import { logout } from 'api/auth';
import MobileMenu from './MobileMenu';

export const purpleSunColor = '#8167e8';

const PurpleSunHeader = ({ logo_url, background_color, display_searchbox, editable, onPressEdit, ...props }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { menu } = useMenu();
  const { menu_node } = menu || {};
  const { menu_items } = menu_node || {};
  const location = useLocation();
  const queryParams = qs.parse((location.search || '').slice(1));
  const { auth } = useAuth();
  const isLoggedIn = auth.user;

  const onPressSignUp = () => {
    window.location.href = '/purplesun/signup';
  };

  const onPressLogin = () => {
    window.location.href = '/purplesun/login';
  };

  const onPressLogout = () => {
    logout();
    window.location.href = '/purplesun/login';
  };

  return (
    <MKBox px={{ xs: 0, md: 1 }} py={{ xs: 1, md: 2 }} bgColor={background_color} {...props}>
      <Container>
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          <MKBox display="flex" alignItems="center">
            <Image
              src={logo_url}
              height="2rem"
              mr={2}
            />
          </MKBox>
          <MKBox display={{ xs: 'none', lg: 'flex' }}>
            <MKBox
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flexWrap="wrap"
            >
              {(menu_items || []).map((menuItem, idx) => {
                const { label: menuItemLabel, path, disabled } = menuItem;
                return !disabled ? (
                  <Button
                    key={`${idx}-${path}`}
                    to={`${path}?${qs.stringify(pick(queryParams, 'mode'))}`}
                    variant="text"
                    color={location.pathname === path ? 'dark' : 'secondary'}
                    fontSize={16}
                    size="small"
                  >
                    {menuItemLabel}
                  </Button>
                ) : null;
              })}
            </MKBox>
          </MKBox>
          {!isLoggedIn ? (
            <MKBox>
              <Button
                variant="outlined"
                color="#8167e8"
                size="medium"
                onClick={onPressSignUp}
                sx={{ mr: 2 }}
              >
                Sign Up
              </Button>
              <Button
                variant="contained"
                color="#8167e8"
                size="medium"
                ml={1}
                onClick={onPressLogin}
              >
                Log In
              </Button>
            </MKBox>
          ) : (
            <MKBox>
              <Button
                variant="contained"
                color="#8167e8"
                size="medium"
                onClick={onPressLogout}
                ml={1}
              >
                Log Out
              </Button>
            </MKBox>
          )}
          <MKBox
            display={{ xs: 'flex', lg: 'none' }}
            lineHeight={0}
            pl={1.5}
            sx={{ cursor: 'pointer' }}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <Icon fontSize="default" color="dark">
              {mobileMenuOpen ? 'close' : 'menu'}
            </Icon>
          </MKBox>
        </MKBox>
        <MKBox
          shadow="lg"
          borderRadius="xl"
          display={{ xs: 'flex', lg: 'none' }}
          px={2}
        >
          <MobileMenu
            menuItems={menu_items}
            open={mobileMenuOpen}
            setOpen={setMobileMenuOpen}
          />
        </MKBox>
      </Container>
    </MKBox>
  );
};

PurpleSunHeader.propTypes = {
  logo_url: PropTypes.string,
  background_color: PropTypes.string,
  display_searchbox: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default PurpleSunHeader;
