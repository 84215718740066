import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormControl, FormControlLabel, Radio, Grid, RadioGroup } from '@mui/material';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import InputField from 'components/InputField';
import { useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { purpleSunColor } from 'sections/PurpleSunMenu';
import { useAuth } from 'contexts/auth';
import { aum_options, current_fund_in_market_options, employees_count_options, fund_size_options, gp_options, hocloc_options, sector_options, vc_aum_options, tc_rr_options, tc_er_options, tc_shpr_options, nofc_options, funds } from '../FundManagerForm/options';

const FundManagerOnlineForm = ({ onAction,
  initialValues,
  ...props }) => {
  const { auth } = useAuth();
  const userRoleScopes = useMemo(() => auth.user?.role?.scopes || [], [auth.user]);
  const hasUpdateFormAccess = !!userRoleScopes.find((roleScope) => roleScope?.scope === 'update_form');

  const [searchParams] = useSearchParams();
  const username = searchParams.get('fpId');
  const navigate = useNavigate();
  const fundsData = useMemo(() => {
    return initialValues.funds.reduce((acc, fund, index) => {
      const fundId = index + 1; // This will start from 1 and increment for each fund
      return {
        ...acc,
        [`fund_${fundId}_vintage`]: fund.vintage,
        [`fund_${fundId}_ai`]: fund.amount_invested,
        [`fund_${fundId}_net_irr`]: fund.irr,
        [`fund_${fundId}_dpi`]: fund.dpi,
        [`fund_${fundId}_tvpi`]: fund.tvpi,
      };
    }, {});
  }, [initialValues.funds]);

  const initvValues = useMemo(() => { return { ...initialValues, ...fundsData, current_fund_in_market: initialValues.current_fund_in_market === true ? '1' : '0' }; }, [fundsData, initialValues]);

  return (
    <Formik
      initialValues={initvValues}
      onSubmit={onAction}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, dirty }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit} {...props}>
            <FormControl>
              <MKBox display="flex" justifyContent="space-between" mb={2}>
                <MKBox
                  display="flex"
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={() => {
                      navigate(-1);
                    }}
                    sx={{ mr: 2 }}
                  >
                    <MKTypography color="white" fontSize="14px" fontWeight="bold">
                      Back
                    </MKTypography>
                  </Button>
                  <MKTypography variant="body1" fontSize="16px" fontWeight="bold">
                    {`User Mgt List > Edit Online Form > ${username} ` }
                  </MKTypography>
                </MKBox>
                {hasUpdateFormAccess && (
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  disabled={!dirty}
                  color={purpleSunColor}
                >
                  <MKTypography color="white" fontSize="14px" fontWeight="bold">
                    Update
                  </MKTypography>
                </Button>
                )}
              </MKBox>
              <Grid container spacing={1}>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Manager headquarter Geographic Location
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="hgl"
                        label=""
                        value={values.hgl}
                        onChange={handleChange('hgl')}
                        onBlur={handleBlur('hgl')}
                        type="select"
                        options={hocloc_options}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Manager 2nd Geographic Location
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="hgl2"
                        label=""
                        value={values.hgl2}
                        onChange={handleChange('hgl2')}
                        onBlur={handleBlur('hgl2')}
                        type="select"
                        options={hocloc_options}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Manager current AUM in total (USD)
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="current_aum"
                        label=""
                        value={values.current_aum}
                        onChange={handleChange('current_aum')}
                        onBlur={handleBlur('current_aum')}
                        type="select"
                        options={aum_options}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Manager current Venture Capital AUM in total (USD)
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="current_vc_aum"
                        label=""
                        value={values.current_vc_aum}
                        onChange={handleChange('current_vc_aum')}
                        onBlur={handleBlur('current_vc_aum')}
                        type="select"
                        options={vc_aum_options}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Manager Years of Operation (Current GP)
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="current_gp"
                        label=""
                        value={values.current_gp}
                        onChange={handleChange('current_gp')}
                        onBlur={handleBlur('current_gp')}
                        type="select"
                        options={gp_options}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Manager Current Full-time employee number
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="current_employee_number"
                        label=""
                        value={values.current_employee_number}
                        onChange={handleChange('current_employee_number')}
                        onBlur={handleBlur('current_employee_number')}
                        type="select"
                        options={employees_count_options}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Manager Current targeted Fund size in Market
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="current_targeted_fund_size"
                        label=""
                        value={values.current_targeted_fund_size}
                        onChange={handleChange('current_targeted_fund_size')}
                        onBlur={handleBlur('current_targeted_fund_size')}
                        type="select"
                        options={fund_size_options}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography mb={2} variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Investment Strategies:
                      </MKTypography>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Geographic Location: Primary
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="tgl_primary"
                        label=""
                        value={values.tgl_primary}
                        type="select"
                        options={hocloc_options}
                        onChange={handleChange('tgl_primary')}
                        onBlur={handleBlur('tgl_primary')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Geographic Location: Secondary
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="tgl_secondary"
                        label=""
                        value={values.tgl_secondary}
                        type="select"
                        options={hocloc_options}
                        onChange={handleChange('tgl_secondary')}
                        onBlur={handleBlur('tgl_secondary')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Geographic Location: Third
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="tgl_third"
                        label=""
                        value={values.tgl_third}
                        type="select"
                        options={hocloc_options}
                        onChange={handleChange('tgl_third')}
                        onBlur={handleBlur('tgl_third')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Sector: Primary
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="ts_primary"
                        label=""
                        value={values.ts_primary}
                        type="select"
                        options={sector_options}
                        onChange={handleChange('ts_primary')}
                        onBlur={handleBlur('ts_primary')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Sector: Secondary
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="ts_secondary"
                        label=""
                        value={values.ts_secondary}
                        type="select"
                        options={sector_options}
                        onChange={handleChange('ts_secondary')}
                        onBlur={handleBlur('ts_secondary')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Sector: Third
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="ts_third"
                        label=""
                        value={values.ts_third}
                        type="select"
                        options={sector_options}
                        onChange={handleChange('ts_third')}
                        onBlur={handleBlur('ts_third')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography mb={2} variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Status of the company at Entry
                      </MKTypography>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Revenue Range
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="trr"
                        label=""
                        value={values.trr}
                        type="select"
                        options={tc_rr_options}
                        onChange={handleChange('trr')}
                        onBlur={handleBlur('trr')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Ebitda Range
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="ter"
                        label=""
                        value={values.ter}
                        type="select"
                        options={tc_er_options}
                        onChange={handleChange('ter')}
                        onBlur={handleBlur('ter')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted Share holding percentage Range
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="shpr"
                        label=""
                        value={values.shpr}
                        type="select"
                        options={tc_shpr_options}
                        onChange={handleChange('shpr')}
                        onBlur={handleBlur('shpr')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography mb={2} variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Manger Track Record @ Current GP:
                      </MKTypography>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        No. of Funds closed:
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="funds_count"
                        label=""
                        value={values.funds_count}
                        type="select"
                        options={nofc_options}
                        onChange={handleChange('funds_count')}
                        onBlur={handleBlur('funds_count')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    {values.funds_count && values.funds_count > 0 && (
                      funds.map((fund) => {
                        const fundId = fund.name;
                        if (values.funds_count < fund.value) {
                          return null;
                        }
                        return (
                          <MKBox key={fundId}>
                            <Grid mb={1} mt={2} container>
                              <Grid item xs={1} alignContent="center">
                                <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                                  {fund.label}
                                </MKTypography>
                              </Grid>
                              <Grid item xs={2} alignContent="center">
                                <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                                  Vintage
                                </MKTypography>
                              </Grid>
                              <Grid item xs={9} alignContent="center">
                                <InputField
                                  disabled={!hasUpdateFormAccess}
                                  name={`${fundId}_vintage`}
                                  label=""
                                  value={values[`${fundId}_vintage`] || ''}
                                  onChange={handleChange(`${fundId}_vintage`)}
                                  onBlur={handleBlur(`${fundId}_vintage`)}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                            <Grid mb={1} container>
                              <Grid item xs={1} alignContent="center" />
                              <Grid item xs={2} alignContent="center">
                                <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                                  Amount Invested
                                </MKTypography>
                              </Grid>
                              <Grid item xs={9} alignContent="center">
                                <InputField
                                  disabled={!hasUpdateFormAccess}
                                  name={`${fundId}_ai`}
                                  label=""
                                  value={values[`${fundId}_ai`] || ''}
                                  onChange={handleChange(`${fundId}_ai`)}
                                  onBlur={handleBlur(`${fundId}_ai`)}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                            <Grid mb={1} container>
                              <Grid item xs={1} alignContent="center" />
                              <Grid item xs={2} alignContent="center">
                                <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                                  Total Net IRR to Investors
                                </MKTypography>
                              </Grid>
                              <Grid item xs={9} alignContent="center">
                                <InputField
                                  disabled={!hasUpdateFormAccess}
                                  name={`${fundId}_net_irr`}
                                  label=""
                                  value={values[`${fundId}_net_irr`] || ''}
                                  onChange={handleChange(`${fundId}_net_irr`)}
                                  onBlur={handleBlur(`${fundId}_net_irr`)}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                            <Grid mb={1} container>
                              <Grid item xs={1} alignContent="center" />
                              <Grid item xs={2} alignContent="center">
                                <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                                  Total DPI
                                </MKTypography>
                              </Grid>
                              <Grid item xs={9} alignContent="center">
                                <InputField
                                  disabled={!hasUpdateFormAccess}
                                  name={`${fundId}_dpi`}
                                  label=""
                                  value={values[`${fundId}_dpi`] || ''}
                                  onChange={handleChange(`${fundId}_dpi`)}
                                  onBlur={handleBlur(`${fundId}_dpi`)}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                            <Grid mb={1} container>
                              <Grid item xs={1} alignContent="center" />
                              <Grid item xs={2} alignContent="center">
                                <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                                  Total TVPI
                                </MKTypography>
                              </Grid>
                              <Grid item xs={9} alignContent="center">
                                <InputField
                                  disabled={!hasUpdateFormAccess}
                                  name={`${fundId}_tvpi`}
                                  label=""
                                  value={values[`${fundId}_tvpi`] || ''}
                                  onChange={handleChange(`${fundId}_tvpi`)}
                                  onBlur={handleBlur(`${fundId}_tvpi`)}
                                  fullWidth
                                />
                              </Grid>
                            </Grid>
                          </MKBox>
                        );
                      })
                    )}
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography mb={2} variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Current Fund in the market? Yes/No
                      </MKTypography>
                      <RadioGroup
                        name="current_fund_in_market"
                        value={values.current_fund_in_market}
                        onChange={(event) => {
                          setFieldValue('current_fund_in_market', event.currentTarget.value);
                        }}
                      >
                        <Grid container mb={2}>
                          <Grid item xs={6}>
                            <FormControlLabel disabled={!hasUpdateFormAccess} value={1} control={<Radio />} label="Yes: Final Closing Time" />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControlLabel disabled={!hasUpdateFormAccess} value={0} control={<Radio />} label="No: Next Fund Launch timeline" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="fund_in_market_time"
                        label=""
                        placeholder="Please Select"
                        value={values.fund_in_market_time}
                        type="select"
                        options={current_fund_in_market_options}
                        onChange={handleChange('fund_in_market_time')}
                        onBlur={handleBlur('fund_in_market_time')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography mb={2} variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Descriptions and Differentiation Comments
                      </MKTypography>
                      <InputField
                        disabled={!hasUpdateFormAccess}
                        name="description"
                        label=""
                        placeholder="Please Select"
                        value={values.description}
                        type="text"
                        multiline
                        minRows={4}
                        maxRows={8}
                        onChange={handleChange('description')}
                        onBlur={handleBlur('description')}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                </Grid>
              </Grid>
              {!!errors.form && (
              <MKTypography variant="caption" color="error">{errors.form}</MKTypography>
              )}
            </FormControl>
          </MKBox>
        );
      }}
    </Formik>
  );
};

FundManagerOnlineForm.propTypes = {
  onAction: PropTypes.func,
  initialValues: PropTypes.object,
};

FundManagerOnlineForm.defaultProps = {
  onAction: () => {},
  initialValues: {},
};

export default FundManagerOnlineForm;
