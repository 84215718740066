import React, { useCallback, useState, useEffect, useMemo } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { purpleSunColor } from 'sections/PurpleSunMenu';
import { Divider, Grid } from '@mui/material';
// eslint-disable-next-line import/no-cycle
import { getDynamicData } from 'utils/sections';
import { getCollectionDefinition } from 'api/collection_definitions';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { PropTypes } from 'prop-types';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getDynamicTableRows, createDynamicTableRow } from 'api/sections';
import Button from 'components/Button';
import { aum_options, employees_count_options, fund_size_options, gp_options, hocloc_options, sector_options, vc_aum_options, tc_rr_options, tc_er_options, tc_shpr_options } from '../FundManagerForm/options';

const contactRequestCollectionDefinitionId = 'a18096ba-a8bf-4ced-a971-3fef7a9ec0c7';

const PurpleSunFundManagerInfo = ({ ...props }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const fundUsername = searchParams.get('fpId');
  const [fm, setFM] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const { auth, setAuth } = useAuth();
  const user = auth?.user;
  const [collectionDefinition, setCollectionDefinition] = useState({});
  const [userDetailsCollectionDefinition, setUserDetailsCollectionDefinition] = useState({});
  const [hasPermission, setHasPermission] = useState(false);
  const [sentPermissionRequest, setSentPermissionRequest] = useState(false);

  const fetchCollectionDefinitions = useCallback(() => {
    return Promise.all([
      getCollectionDefinition('556ee905-4a79-4e41-a3a5-e1b11decbc4b', { $expand: 'attributes' })
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        }),
      getCollectionDefinition('5970597b-affd-47c1-bdb4-7014dca4756b', { $expand: 'attributes' })
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        })])
      .then(([target, userdetails]) => {
        setCollectionDefinition(target);
        setUserDetailsCollectionDefinition(userdetails);
      });
  }, [setAuth]);

  const fetchUserDetailsFromApi = useCallback(() => {
    if (!fundUsername) return Promise.resolve();
    return getDynamicTableRows('5970597b-affd-47c1-bdb4-7014dca4756b', { 'created_by[eq]': fundUsername })
      .then(({ data }) => {
        const humanReadableData = data.map((row) => {
          return getDynamicData(row, userDetailsCollectionDefinition.attributes);
        });
        setUserDetails(humanReadableData);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth, fundUsername, userDetailsCollectionDefinition.attributes]);

  const fetchFPdataFromApi = useCallback(() => {
    if (!fundUsername) return Promise.resolve();
    return getDynamicTableRows('556ee905-4a79-4e41-a3a5-e1b11decbc4b', { 'created_by[eq]': fundUsername })
      .then(({ data }) => {
        const humanReadableData = data.map((row) => {
          const { json_short_data } = row;
          const dynamicData = getDynamicData(row, collectionDefinition.attributes);
          const jso = JSON.parse(json_short_data);
          const fundsData = JSON.parse(jso['6038803c-4b0b-460b-bbb9-89a584ae61b5']);
          dynamicData.funds = fundsData;
          dynamicData.id = row.id;
          return dynamicData;
        });
        setFM(humanReadableData);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [collectionDefinition?.attributes, setAuth, fundUsername]);

  useEffect(() => {
    if (!collectionDefinition.collection_definition_id || !userDetailsCollectionDefinition.collection_definition_id) {
      fetchCollectionDefinitions();
    }
    if (user.role.role_id === 3) {
      navigate('/fund_manager/forms');
    }
    fetchUserDetailsFromApi();
    fetchFPdataFromApi();
  }, [collectionDefinition.collection_definition_id, fetchCollectionDefinitions, fetchFPdataFromApi, fetchUserDetailsFromApi, navigate, user.role.role_id, userDetailsCollectionDefinition.collection_definition_id]);

  const userInfo = useMemo(() => userDetails?.[0], [userDetails]);
  const formDetails = useMemo(() => fm?.[0], [fm]);

  const sendContactRequest = () => {
    const createBody = {
      json_short_data: JSON.stringify({
        'dbd919ef-603f-463d-89ee-3849b71449f3': fundUsername,
        'f15f8815-bcf9-4f39-b4a9-af8b52191c9f': user.email,
        '49e04031-9c8a-4eb6-9ccf-dead390afd58': false,
        '9eb8999d-a073-48d1-86b9-c0be33e9e43f': false,
        'fd104382-88d1-46b8-bb20-3f7f59b87278': user.display_name,
        '0b64974c-f822-4543-907c-e8b0e2509aa1': `${userInfo?.first_name}  ${userInfo?.last_name}`,
      }),
    };
    if (sentPermissionRequest) { alert('You already sent the request!'); return; }
    createDynamicTableRow(contactRequestCollectionDefinitionId, createBody)
      .then((response) => {
        setSentPermissionRequest(true);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  };

  const checkIfContactRequestApproved = useCallback(() => {
    return getDynamicTableRows(contactRequestCollectionDefinitionId, { 'created_by[eq]': user.email })
      .then(({ data }) => {
        const permissions = data.map((row) => {
          const rowDetails = JSON.parse(row.json_short_data);
          return rowDetails;
        }).filter((row) => row['dbd919ef-603f-463d-89ee-3849b71449f3'] === fundUsername);
        if (permissions.length > 0) {
          setHasPermission(permissions[0]['49e04031-9c8a-4eb6-9ccf-dead390afd58'] !== 'false');
          setSentPermissionRequest(true);
        }
      });
  }, [fundUsername, user.email]);

  useEffect(() => {
    checkIfContactRequestApproved();
  }, [checkIfContactRequestApproved]);

  const getLabel = (value, options) => {
    const option = options.find((opt) => opt.value === value);
    return option?.label;
  };

  const renderFundInfo = (details) => {
    let i = 0;
    return details.funds.map((fund) => {
      i += 1;
      return (
        <>
          <Grid item xs={4}>
            <MKTypography ml={6} variant="body1" fontWeight="bold" fontSize="16px" color="black">
              {`Fund ${i}:`}
            </MKTypography>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={4}>
            <MKTypography ml={6} variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Vintage:
            </MKTypography>
          </Grid>
          <Grid item xs={8}>
            <MKTypography variant="body1" fontSize="16px" color="black">
              {fund?.vintage}
            </MKTypography>
          </Grid>
          <Grid item xs={4}>
            <MKTypography ml={6} variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Amount Invested:
            </MKTypography>
          </Grid>
          <Grid item xs={8}>
            <MKTypography variant="body1" fontSize="16px" color="black">
              {fund?.amount_invested}
            </MKTypography>
          </Grid>
          <Grid item xs={4}>
            <MKTypography ml={6} variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Total Net IRR to Investors:
            </MKTypography>
          </Grid>
          <Grid item xs={8}>
            <MKTypography variant="body1" fontSize="16px" color="black">
              {fund?.irr}
            </MKTypography>
          </Grid>
          <Grid item xs={4}>
            <MKTypography ml={6} variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Total DPI
            </MKTypography>
          </Grid>
          <Grid item xs={8}>
            <MKTypography variant="body1" fontSize="16px" color="black">
              {fund?.dpi}
            </MKTypography>
          </Grid>
          <Grid item xs={4}>
            <MKTypography ml={6} variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Total TVPI
            </MKTypography>
          </Grid>
          <Grid item xs={8}>
            <MKTypography variant="body1" fontSize="16px" color="black">
              {fund?.tvpi}
            </MKTypography>
          </Grid>
        </>
      );
    });
  };

  const userRole = user?.role.role_id;
  let nameToDisplay;
  if (userInfo?.first_name || userInfo?.last_name) {
    nameToDisplay = `${userInfo?.first_name}  ${userInfo?.last_name}`;
  } else if (userInfo?.display_name) {
    nameToDisplay = userInfo?.display_name;
  } else {
    nameToDisplay = userInfo?.email;
  }

  return (
    <MKBox mt={2} mb={4} display="flex" flexDirection="column">
      <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <MKTypography variant="h1" fontSize="24px" color="black">
          {nameToDisplay}
          &apos;s Info
        </MKTypography>
        {userRole === 4 && !hasPermission && (
        <MKBox mt={2}>
          <Button
            variant="contained"
            size="small"
            color={purpleSunColor}
            onClick={() => { sendContactRequest(); }}
          >
            <MKTypography variant="body1" fontWeight="bold" fontSize="14px" color="white">
              {sentPermissionRequest ? 'Awaiting Approval' : 'Find More'}
            </MKTypography>
          </Button>
        </MKBox>
        )}
      </MKBox>
      {userRole === 4 && hasPermission && (
      <MKBox
        mt={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="left"
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
              {user.display_name}
            </MKTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Tel:&nbsp;
              {userInfo?.mobile}
            </MKTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Email:&nbsp;
              {userInfo?.email}
            </MKTypography>
          </Grid>
        </Grid>
      </MKBox>
      )}
      <Divider />
      <MKBox
        display="flex"
        flexDirection="column"
        alignItems="left"
      >
        <MKBox>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
                Manager Overview:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`HQ Geographic location: ${getLabel(formDetails?.hgl, hocloc_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Secondary Geographic location: ${getLabel(formDetails?.hgl2, hocloc_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Current AUM in Total (USD): ${getLabel(formDetails?.current_aum, aum_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Current Venture Capital AUM in Total (USD): ${getLabel(formDetails?.current_vc_aum, vc_aum_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Years of Operation (Current GP): ${getLabel(formDetails?.current_gp, gp_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Full-time employee count: ${getLabel(formDetails?.current_employee_number, employees_count_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Targeted fund size in market: ${getLabel(formDetails?.current_targeted_fund_size, fund_size_options)}`}
              </MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
                Investment Strategies:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Geographic location: ${getLabel(formDetails?.tgl_primary, hocloc_options)}, ${getLabel(formDetails?.tgl_secondary, hocloc_options)}, ${getLabel(formDetails?.tgl_third, hocloc_options)} `}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Target Sectors: ${getLabel(formDetails?.ts_primary, sector_options)}, ${getLabel(formDetails?.ts_secondary, sector_options)}, ${getLabel(formDetails?.ts_third, sector_options)}`}
              </MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
                Status of the company at Entry:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Targeted Revenue Range: ${getLabel(formDetails?.trr, tc_rr_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Targeted Ebitda Range: ${getLabel(formDetails?.ter, tc_er_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Targeted Share holding Percentage rate: ${getLabel(formDetails?.shpr, tc_shpr_options)}`}
              </MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
                Manger Track Record @Current GP No. of Funds closed:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {formDetails?.funds_count}
              </MKTypography>
            </Grid>
            {
            formDetails?.funds_count >= 1 && renderFundInfo(formDetails)
            }
            <Grid item xs={4}>
              <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
                Current Fund in the market? Yes/No
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {formDetails?.current_fund_in_market ? 'Yes' : 'No'}
              </MKTypography>
              {/* <MKTypography variant="body1" fontSize="16px" color="black">
                {`${formDetails?.current_fund_in_market ? 'Final Closing Time' : 'Next Fund Launch timeline:'} ${datetimeFormatter(formDetails?.fund_in_market_time)}`}
              </MKTypography> */}
            </Grid>
          </Grid>
        </MKBox>

      </MKBox>
    </MKBox>
  );
};

PurpleSunFundManagerInfo.propTypes = {
  current_fund_in_market: PropTypes.bool,
};

export default PurpleSunFundManagerInfo;
