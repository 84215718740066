import MKBox from 'components/MaterialKit/MKBox';
import { createDynamicTableRow } from 'api/sections';
import { useAuth } from 'contexts/auth';
import { handleErrorResponse } from 'utils/general';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import FundManagerOnlineForm from './form';

const PurpleSunFundManagerForm = () => {
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const fm = auth.user;
  const submitForm = (values) => {
    let i = 0;
    const boolVal = values.current_fund_in_market === '1';
    const json_short_data = {
      '993d603a-e075-497f-b7df-a1259b3dae05': values.hgl,
      '141ffc2c-8e9c-4045-863a-d35b478a65b4': values.hgl2,
      '7204b2cc-0fc0-4f48-bd53-cac55bdde96e': values.current_aum,
      'ae254241-138c-43ae-b0cb-57f7ff8c8893': values.current_vc_aum,
      'e79b429f-e493-4222-ad82-5bda12c92b0e': values.current_gp,
      'bef3d55a-e36d-417c-b0c7-af15b9845af1': values.current_employee_number,
      '4a6ec2da-d11d-4f68-8a18-4f6c2bc149d2': values.current_targeted_fund_size,
      'caf559ae-b221-4d62-b942-d36f705f4c86': values.tgl_primary,
      '9ee52b2e-8e6d-4d4e-a18b-b783480a4eca': values.tgl_secondary,
      '98c1a63c-c081-4515-81b4-a4354dae4296': values.tgl_third,
      '8096e325-21c8-4bb2-b69b-bc603c59419e': values.ts_primary,
      'd14799ec-7036-411d-a08c-9598aa0a37e3': values.ts_secondary,
      'bdebac95-8292-4918-9cfb-b99a1fa546a5': values.ts_third,
      'eb9954c1-bf14-4311-bab5-a659d50f288f': values.trr,
      'b82c6577-ab65-4b3a-82f6-61143a32ff6b': values.ter,
      'aa9f0303-841b-4d85-a8a4-e169aac4857b': values.shpr,
      '6796806c-f38f-4492-9e9e-f87884e5f57c': values.funds_count,
      '5df5762d-3ed3-4548-9da8-29046e73e804': values.fund_in_market_time,
      '5a971eb2-dbe0-4e2e-932a-d6801c897a39': boolVal,
      'c245cb57-1f8f-4f66-92cd-545314f946a0': values.description,
      'f858ccc9-1d62-41bb-b0ea-3c1f53e9db85': 'Pending',
      '18e547b6-28a6-45a2-a5c5-0330d6f36a0f': fm.username,
      '01919888-08a0-78b1-80db-22f35111130b': fm.display_name,
    };
    const funds = [];
    while (i < values.funds_count) {
      i += 1;
      const fund_ai = `fund_${i}_ai`;
      const fund_dpi = `fund_${i}_dpi`;
      const fund_net_irr = `fund_${i}_net_irr`;
      const fund_tvpi = `fund_${i}_tvpi`;
      const fund_vintage = `fund_${i}_vintage`;
      const fund_data = {
        amount_invested: values[fund_ai],
        dpi: values[fund_dpi],
        irr: values[fund_net_irr],
        tvpi: values[fund_tvpi],
        vintage: values[fund_vintage],
      };
      funds.push(fund_data);
    }
    json_short_data['6038803c-4b0b-460b-bbb9-89a584ae61b5'] = JSON.stringify(funds);
    const createBody = { json_short_data: JSON.stringify(json_short_data) };
    createDynamicTableRow('556ee905-4a79-4e41-a3a5-e1b11decbc4b', createBody)
      .then((response) => {
        if (response.status === 200) {
          navigate('/fund_manager/forms');
        }
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  };

  if (fm.role.role_id === 4) {
    navigate('/investor');
  }

  return (
    <MKBox>
      <FundManagerOnlineForm onAction={submitForm} viewOnly={false} />
    </MKBox>
  );
};

export default PurpleSunFundManagerForm;
