export const hocloc_options = [
  { value: 'northamerica', label: 'North America' },
  { value: 'southcentralamerica', label: 'South & Central America' },
  { value: 'unitedstates', label: 'United States' },
  { value: 'europe', label: 'Europe (other)' },
  { value: 'westerneurope', label: 'Western Europe' },
  { value: 'mideast', label: 'Mid East' },
  { value: 'israel', label: 'Israel' },
  { value: 'chine', label: 'Greater China' },
  { value: 'india', label: 'India' },
  { value: 'southeastasia', label: 'South East Asia' },
  { value: 'japan', label: 'Japan' },
  { value: 'korea', label: 'Korea' },
  { value: 'australia', label: 'Australia' },
];
export const sector_options = [
  { value: 'ai', label: 'AI' },
  { value: 'health_tech', label: 'Health Tech' },
  { value: 'life_science', label: 'Life Science' },
  { value: 'space_science', label: 'Space Science' },
  { value: 'saas', label: 'SaaS' },
  { value: 'material_science', label: 'Material Science' },
  { value: 'robotics', label: 'Robotics' },
  { value: 'ecommerce', label: 'Ecommerce' },
  { value: 'media', label: 'Media' },
  { value: 'entertainment', label: 'Entertainment' },
  { value: 'consumer_brand', label: 'Consumer Brand' },
  { value: 'fintech', label: 'Fintech' },
  { value: 'web3', label: 'Web3' },
];
export const tc_rr_options = [
  { value: '0-1', label: 'Pre Rev' },
  { value: '1-5', label: '1-5mil' },
  { value: '5-20', label: '5-20mil' },
  { value: '20-50', label: '20-50mil' },
  { value: '50-100', label: '50-100mil' },
  { value: '100-200', label: '100-200mil' },
  { value: '200-300', label: '200-300mil' },
  { value: '300+', label: '300mil+' },
];
export const tc_er_options = [
  { value: '0-1', label: 'Before break even' },
  { value: '1-5', label: '1-5mil' },
  { value: '5-10', label: '5-10mil' },
  { value: '10-20', label: '10-20mil' },
  { value: '20+', label: '20mil+' },
];
export const tc_shpr_options = [
  { value: '0-5', label: 'Less than 5%' },
  { value: '5-10', label: '5-10%' },
  { value: '10-20', label: '10-20%' },
  { value: '20-30', label: '20-30%' },
  { value: '30-40', label: '30-40%' },
  { value: '40-50', label: '40-50%' },
  { value: '50+', label: '50%+' },
];
export const nofc_options = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];
export const aum_options = [
  { value: '0-100', label: 'less than 100mil' },
  { value: '100-200', label: '100mil to 200mil' },
  { value: '200-300', label: '200mil to 300mil' },
  { value: '300-400', label: '300mil to 400mil' },
  { value: '400-500', label: '400mil to 500mil' },
  { value: '500+', label: '500mil+' },
];
export const vc_aum_options = [
  { value: '0-50', label: 'less than 50mil' },
  { value: '50-100', label: '50mil to 100mil' },
  { value: '100-150', label: '100mil to 150mil' },
  { value: '150-200', label: '150mil to 200mil' },
  { value: '200+', label: '200mil+' },
];
export const gp_options = [
  { value: '0-2', label: 'Less than 2' },
  { value: '2-5', label: '2 to 5' },
  { value: '5-10', label: '5 to 10' },
  { value: '10+', label: '10+' },
];
export const employees_count_options = [
  { value: '0-5', label: 'Less than 5' },
  { value: '5-10', label: '5 to 10' },
  { value: '10-30', label: '10 to 30' },
  { value: '30+', label: '30+' },
];
export const fund_size_options = [
  { value: '0-55', label: 'Less than 50mil' },
  { value: '50-100', label: '50mil to 100mil' },
  { value: '100-200', label: '100mil to 200mil' },
  { value: '200', label: '200mil+' },
];
export const current_fund_in_market_options = [
  { value: 'q12024', label: 'Q1 2024' },
  { value: 'q22024', label: 'Q2 2024' },
  { value: 'q32024', label: 'Q3 2024' },
  { value: 'q42024', label: 'Q4 2024' },
  { value: 'q12025', label: 'Q1 2025' },
  { value: 'q22025', label: 'Q2 2025' },
  { value: 'q32025', label: 'Q3 2025' },
  { value: 'q42025', label: 'Q4 2025' },
];

export const funds = [
  { name: 'fund_1', label: 'Fund 1', value: 1, vintage: '', ai: '', net_irr: '', dpi: '', tvpi: '' },
  { name: 'fund_2', label: 'Fund 2', value: 2, vintage: '', ai: '', net_irr: '', dpi: '', tvpi: '' },
  { name: 'fund_3', label: 'Fund 3', value: 3, vintage: '', ai: '', net_irr: '', dpi: '', tvpi: '' },
  { name: 'fund_4', label: 'Fund 4', value: 4, vintage: '', ai: '', net_irr: '', dpi: '', tvpi: '' },
  { name: 'fund_5', label: 'Fund 5', value: 5, vintage: '', ai: '', net_irr: '', dpi: '', tvpi: '' },
];
