import React, { useCallback, useState, useEffect, useMemo } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { Divider, Grid, Stack } from '@mui/material';
import { getDynamicTableRows, updateDynamicTableRow } from 'api/sections';
// eslint-disable-next-line import/no-cycle
import { getDynamicData } from 'utils/sections';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { datetimeFormatter } from 'utils/datetime';
import { PropTypes } from 'prop-types';
import { getCollectionDefinition } from 'api/collection_definitions';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import { aum_options, employees_count_options, fund_size_options, gp_options, hocloc_options, sector_options, vc_aum_options, tc_rr_options, tc_er_options, tc_shpr_options, current_fund_in_market_options } from '../FundManagerForm/options';

const StaffFundManagerMgtPage = ({ user, ...props }) => {
  const { auth, setAuth } = useAuth();
  const userRoleScopes = useMemo(() => auth.user?.role?.scopes || [], [auth.user]);
  const hasDecisionAccess = !!userRoleScopes.find((roleScope) => roleScope?.scope === 'admin_only' || 'staff_only');
  const hasUpdateFormAccess = !!userRoleScopes.find((roleScope) => roleScope?.scope === 'update_form');

  const navigate = useNavigate();
  const [fm, setFM] = useState(null);
  const [dynamicObject, setDynamicObject] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [collectionDefinition, setCollectionDefinition] = useState({});
  const [userDetailsCollectionDefinition, setUserDetailsCollectionDefinition] = useState({});

  const fetchCollectionDefinitions = useCallback(() => {
    return Promise.all([
      getCollectionDefinition('556ee905-4a79-4e41-a3a5-e1b11decbc4b', { $expand: 'attributes' })
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        }),
      getCollectionDefinition('5970597b-affd-47c1-bdb4-7014dca4756b', { $expand: 'attributes' })
        .then(({ data }) => {
          return data;
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        })])
      .then(([target, userdetails]) => {
        setCollectionDefinition(target);
        setUserDetailsCollectionDefinition(userdetails);
      });
  }, [setAuth]);

  const fetchUserDetailsFromApi = useCallback(() => {
    if (!user?.username) return Promise.resolve();
    return getDynamicTableRows('5970597b-affd-47c1-bdb4-7014dca4756b', { 'created_by[eq]': user?.username })
      .then(({ data }) => {
        const humanReadableData = data.map((row) => {
          return getDynamicData(row, userDetailsCollectionDefinition.attributes);
        });
        setUserDetails(humanReadableData);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth, user?.username, userDetailsCollectionDefinition.attributes]);

  const fetchFPdataFromApi = useCallback(() => {
    if (!user?.username) return Promise.resolve();
    return getDynamicTableRows('556ee905-4a79-4e41-a3a5-e1b11decbc4b', { 'created_by[eq]': user?.username })
      .then(({ data }) => {
        const humanReadableData = data.map((row) => {
          const { json_short_data } = row;
          const dynamicData = getDynamicData(row, collectionDefinition.attributes);
          const jso = JSON.parse(json_short_data);
          const fundsData = JSON.parse(jso['6038803c-4b0b-460b-bbb9-89a584ae61b5']);
          dynamicData.funds = fundsData;
          dynamicData.id = row.id;
          return dynamicData;
        });
        setDynamicObject(data[0]);
        setFM(humanReadableData);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [collectionDefinition?.attributes, setAuth, user?.username]);

  const rejectForm = useCallback((id) => {
    const { json_short_data } = dynamicObject;
    const data = JSON.parse(json_short_data);
    data['f858ccc9-1d62-41bb-b0ea-3c1f53e9db85'] = 'Rejected';
    const updateBody = { json_short_data: JSON.stringify(data) };
    updateDynamicTableRow('556ee905-4a79-4e41-a3a5-e1b11decbc4b', id, updateBody)
      .then(() => {
        window.location.reload();
      });
  }, [dynamicObject]);

  const approveForm = useCallback((id) => {
    const { json_short_data } = dynamicObject;
    const data = JSON.parse(json_short_data);
    data['f858ccc9-1d62-41bb-b0ea-3c1f53e9db85'] = 'Approved';
    const updateBody = { json_short_data: JSON.stringify(data) };
    updateDynamicTableRow('556ee905-4a79-4e41-a3a5-e1b11decbc4b', id, updateBody)
      .then(() => {
        window.location.reload();
      });
  }, [dynamicObject]);

  const updateSubmissionStatus = useCallback((status, id) => {
    if (status === 'rejected') {
      rejectForm(id);
    } if (status === 'approved') {
      approveForm(id);
    }
  }, [approveForm, rejectForm]);

  useEffect(() => {
    if (!collectionDefinition.collection_definition_id || !userDetailsCollectionDefinition.collection_definition_id) {
      fetchCollectionDefinitions();
    }
    fetchUserDetailsFromApi();
    fetchFPdataFromApi();
  }, [collectionDefinition.collection_definition_id, fetchCollectionDefinitions, fetchFPdataFromApi, fetchUserDetailsFromApi, userDetailsCollectionDefinition.collection_definition_id]);

  const userInfo = useMemo(() => userDetails?.[0], [userDetails]);
  const formDetails = useMemo(() => fm?.[0], [fm]);
  const id = formDetails?.id;
  const status = formDetails?.status;

  const editForm = useCallback(() => {
    navigate(`/staff/edit_form?fpId=${userInfo?.email}`, { state: { form: fm } });
  }, [fm, navigate, userInfo?.email]);

  const getLabel = (value, options) => {
    const option = options.find((opt) => opt.value === value);
    return option?.label;
  };

  const renderFundInfo = (details) => {
    let i = 0;
    return details.funds.map((fund) => {
      i += 1;
      return (
        <>
          <Grid item xs={4}>
            <MKTypography ml={6} variant="body1" fontWeight="bold" fontSize="16px" color="black">
              {`Fund ${i}:`}
            </MKTypography>
          </Grid>
          <Grid item xs={8} />
          <Grid item xs={4}>
            <MKTypography ml={6} variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Vintage:
            </MKTypography>
          </Grid>
          <Grid item xs={8}>
            <MKTypography variant="body1" fontSize="16px" color="black">
              {fund?.vintage}
            </MKTypography>
          </Grid>
          <Grid item xs={4}>
            <MKTypography ml={6} variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Amount Invested:
            </MKTypography>
          </Grid>
          <Grid item xs={8}>
            <MKTypography variant="body1" fontSize="16px" color="black">
              {fund?.amount_invested}
            </MKTypography>
          </Grid>
          <Grid item xs={4}>
            <MKTypography ml={6} variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Total Net IRR to Investors:
            </MKTypography>
          </Grid>
          <Grid item xs={8}>
            <MKTypography variant="body1" fontSize="16px" color="black">
              {fund?.irr}
            </MKTypography>
          </Grid>
          <Grid item xs={4}>
            <MKTypography ml={6} variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Total DPI
            </MKTypography>
          </Grid>
          <Grid item xs={8}>
            <MKTypography variant="body1" fontSize="16px" color="black">
              {fund?.dpi}
            </MKTypography>
          </Grid>
          <Grid item xs={4}>
            <MKTypography ml={6} variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Total TVPI
            </MKTypography>
          </Grid>
          <Grid item xs={8}>
            <MKTypography variant="body1" fontSize="16px" color="black">
              {fund?.tvpi}
            </MKTypography>
          </Grid>
        </>
      );
    });
  };
  return (
    <MKBox mt={2} display="flex" flexDirection="column">
      <MKTypography variant="h1" fontSize="24px" color="black">
        {user.display_name}
        &apos;s Info
      </MKTypography>
      <MKBox
        mt={2}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="left"
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Tel:&nbsp;
              {userInfo?.mobile}
            </MKTypography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
              Email:&nbsp;
              {userInfo?.email}
            </MKTypography>
          </Grid>
          <Grid xs={12} sm={12} md={4} lg={4} container item justifyContent="end">
            <Button variant="contained" size="small" color="#00A55E" onClick={() => { navigate(`/staff/manage/edit?fpId=${userInfo?.email}`); }}>
              <MKTypography color="white" fontSize="14px" fontWeight="bold">
                Edit User Info
              </MKTypography>
            </Button>
          </Grid>
        </Grid>
      </MKBox>
      <Divider />
      <MKBox
        display="flex"
        flexDirection="column"
        alignItems="left"
      >
        <MKBox display="flex" flexDirection="row" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            <MKTypography variant="h4" fontWeight="bold" fontSize="28px" color="black">
              Online Form
            </MKTypography>
            {hasUpdateFormAccess && (
              <Button
                variant="contained"
                color="#00A55E"
                size="small"
                onClick={editForm}
              >
                <MKTypography color="white" fontSize="14px" fontWeight="bold">
                  Modify Form
                </MKTypography>
              </Button>
            )}
          </Stack>
          <MKBox>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
              <MKTypography variant="body1" fontWeight="bold" fontSize="14px" color="black">
                Form status:
                {' '}
                {status}
              </MKTypography>
              {hasDecisionAccess && (
              <MKBox>
                <Button
                  variant="outlined"
                  color="gray"
                  size="small"
                  onClick={() => updateSubmissionStatus('rejected', id)}
                >
                  <MKTypography color="gray" fontSize="16px" fontWeight="bold">
                    Reject
                  </MKTypography>
                </Button>
                <Button
                  variant="contained"
                  color="#6628D0"
                  size="small"
                  onClick={() => updateSubmissionStatus('approved', id)}
                  style={{ marginLeft: 15 }}
                >
                  <MKTypography color="white" fontSize="16px" fontWeight="bold">
                    Approve
                  </MKTypography>
                </Button>
              </MKBox>
              )}
            </Stack>
          </MKBox>

        </MKBox>

        <MKBox>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
                Manager Overview:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`HQ Geographic location: ${getLabel(formDetails?.hgl, hocloc_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Secondary Geographic location: ${getLabel(formDetails?.hgl2, hocloc_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Current AUM in Total (USD): ${getLabel(formDetails?.current_aum, aum_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Current Venture Capital AUM in Total (USD): ${getLabel(formDetails?.current_vc_aum, vc_aum_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Years of Operation (Current GP): ${getLabel(formDetails?.current_gp, gp_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Full-time employee count: ${getLabel(formDetails?.current_employee_number, employees_count_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Targeted fund size in market: ${getLabel(formDetails?.current_targeted_fund_size, fund_size_options)}`}
              </MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
                Investment Strategies:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Geographic location: ${getLabel(formDetails?.tgl_primary, hocloc_options)}, ${getLabel(formDetails?.tgl_secondary, hocloc_options)}, ${getLabel(formDetails?.tgl_third, hocloc_options)} `}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Target Sectors: ${getLabel(formDetails?.ts_primary, sector_options)}, ${getLabel(formDetails?.ts_secondary, sector_options)}, ${getLabel(formDetails?.ts_third, sector_options)}`}
              </MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
                Status of the company at Entry:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Targeted Revenue Range: ${getLabel(formDetails?.trr, tc_rr_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Targeted Ebitda Range: ${getLabel(formDetails?.ter, tc_er_options)}`}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`Targeted Share holding Percentage rate: ${getLabel(formDetails?.shpr, tc_shpr_options)}`}
              </MKTypography>
            </Grid>
            <Grid item xs={4}>
              <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
                Manger Track Record @Current GP No. of Funds closed:
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {formDetails?.funds_count}
              </MKTypography>
            </Grid>
            {
            formDetails?.funds_count >= 1 && renderFundInfo(formDetails)
            }
            <Grid item xs={4}>
              <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
                Current Fund in the market? Yes/No
              </MKTypography>
            </Grid>
            <Grid item xs={8}>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {formDetails?.current_fund_in_market ? 'Yes' : 'No'}
              </MKTypography>
              <MKTypography variant="body1" fontSize="16px" color="black">
                {`${formDetails?.current_fund_in_market ? 'Final Closing Time' : 'Next Fund Launch timeline:'} ${getLabel(formDetails?.fund_in_market_time, current_fund_in_market_options)}`}
              </MKTypography>
            </Grid>
          </Grid>
        </MKBox>

      </MKBox>
    </MKBox>
  );
};

StaffFundManagerMgtPage.propTypes = {
  user: PropTypes.object,
};

export default StaffFundManagerMgtPage;
