import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import { getUser, updateUser } from 'api/users';
import MKTypography from 'components/MaterialKit/MKTypography';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';
import { handleErrorResponse } from 'utils/general';
// eslint-disable-next-line
import StaffFundManagerMgtPage from './fundManager';
import StaffInvestorMgtPage from './investor';

const StaffRoleForkComponent = () => {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const [user, setUser] = useState(null);
  const [searchParams] = useSearchParams();
  const username = searchParams.get('fpId');

  const fetchUser = useCallback(() => {
    if (!username) {
      navigate('/staff');
      return;
    }
    return getUser(username, {}, 'bearer')
      .then(({ data }) => {
        setUser(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [navigate, setAuth, username]);

  const updateUserToApi = useCallback(
    (active) => {
      const body = {
        active,
      };
      updateUser(username, body)
        .then((response) => {
          if (response.status === 200) {
            window.location.reload();
          }
        });
    },
    [username],
  );

  useEffect(() => {
    fetchUser();
    if (user?.role === 2) {
      navigate(`/staff/manage/edit?fpId=${username}`);
    }
  }, [fetchUser, navigate, user?.role, username]);

  return (
    <div>
      <MKBox display="flex" justifyContent="space-between" mt={2}>
        <MKBox
          display="flex"
          alignItems="center"
        >
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => {
              navigate(-1);
            }}
            sx={{ mr: 2 }}
          >
            <MKTypography color="white" fontSize="14px" fontWeight="bold">
              Back
            </MKTypography>
          </Button>
          <MKTypography variant="body1" fontSize="16px" fontWeight="bold">
            {`User Mgt List > ${user?.display_name}`}
          </MKTypography>
        </MKBox>
        <Button
          variant="contained"
          size="small"
          color={user?.active ? '#29ABE2' : 'gray'}
          onClick={() => {
            if (user?.active) updateUserToApi(false);
            else updateUserToApi(true);
          }}
        >
          <MKTypography color="white" fontSize="14px" fontWeight="bold">
            {user?.active ? 'Activate account' : 'Deactivate account'}
          </MKTypography>
        </Button>
      </MKBox>
      {user?.role === 3 && <StaffFundManagerMgtPage user={user} />}
      {user?.role === 4 && <StaffInvestorMgtPage user={user} />}
    </div>
  );
};

export default StaffRoleForkComponent;
