import React from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';

import { useAuth } from 'contexts/auth';
import { useNavigate } from 'react-router-dom';
import { createUser, updateUser } from 'api/users';
import { getClientCredential, login } from 'api/auth';
import { handleErrorResponse } from 'utils/general';
import { setStoredAuth } from 'api';
import { createDynamicTableRow } from 'api/sections';

import PSSignupForm from './signUpForm';

const PurpleSunSignUpPage = () => {
  const { auth, setAuth } = useAuth();
  const { user } = auth;
  const navigate = useNavigate();

  if (user?.username) {
    navigate('/');
    return null;
  }

  getClientCredential()
    .then(({ data }) => {
      setStoredAuth(data);
    })
    .catch((err) => {
      handleErrorResponse(err, setAuth);
    });

  const onSignUpToApi = (values, { setFieldError }) => {
    if (values.iam !== 'allocator' && values.iam !== 'investor') {
      setFieldError('iam', 'Please select a valid role');
      return;
    }
    let role;
    if (values.iam === 'allocator') {
      role = 3;
    } else if (values.iam === 'investor') {
      role = 4;
    }
    // Create a dynamic row in the collection definition storing the clients data, assign its dynamic row id to the user field `app_version` for future referencing
    const createBody = {
      display_name: `${values.first_name} ${values.last_name}`,
      username: values.email,
      email: values.email,
      password: values.password,
      role,
    };
    const json_short_data = {
      'b7bcf430-d03f-4c1f-921b-c941aa0d4be4': values.first_name,
      'fe4cb66e-6a16-42fa-a58f-2bae2b998d75': values.last_name,
      '2847006a-700b-432b-9569-259fc44fdae0': values.email,
      '579eab61-0eed-4fea-815d-e985733a5b81': values.mobile,
      'e2e76a7f-bfb7-478b-b7e4-2d922ff8f05f': values.company_name,
      '3b979c10-c17a-4bf6-be27-c63c43898604': values.job_title,
      '9076abdd-fd41-11ee-8d6b-0637d0944826': values.iam,
    };
    return createUser(createBody)
      .then((response) => {
        if (response.status === 201) {
          return login(values.email, values.password);
        }
      })
      .then((data) => {
        const strJson = JSON.stringify(json_short_data);
        const createRowBody = {
          json_short_data: strJson,
        };
        return createDynamicTableRow('5970597b-affd-47c1-bdb4-7014dca4756b', createRowBody)
          .then((response) => {
            return response.data.id;
          })
          .then((id) => {
            return updateUser(values.email, { user_info_id: id });
          })
          .then((response) => {
            navigate('/login');
          });
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  };

  return (
    <MKBox>
      <MKTypography variant="h1" color="black" textAlign="start" fontSize="36px" mt={6}>
        Sign up
      </MKTypography>
      <PSSignupForm onSignUp={onSignUpToApi} initialValues={{ checkbox: false }} />
    </MKBox>
  );
};

export default PurpleSunSignUpPage;
