import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useAuth } from 'contexts/auth';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { getDynamicTableRows } from 'api/sections';
import BasicTable from 'sections/PurpleSunTable/table';
// eslint-disable-next-line
import { getDynamicData } from 'utils/sections';
import { getCollectionDefinition } from 'api/collection_definitions';
import { handleErrorResponse } from 'utils/general';
import { datetimeFormatter } from 'utils/datetime';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';

const PurpleSunSubmittedForms = () => {
  const { auth, setAuth } = useAuth();
  const user = auth?.user;
  const navigate = useNavigate();
  const [rowsData, setRowsData] = useState(null);
  const [collectionDefinition, setCollectionDefinition] = useState(null);
  const fetchFromApi = useCallback(() => {
    return getDynamicTableRows('556ee905-4a79-4e41-a3a5-e1b11decbc4b', {
      'created_by[eq]': user?.username,
    })
      .then(({ data }) => {
        if (data.length === 0) {
          navigate('/fund-manager-form');
        }
        setRowsData(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [navigate, setAuth, user?.username]);

  const fetchCollectionDefinition = useCallback(() => {
    return getCollectionDefinition('556ee905-4a79-4e41-a3a5-e1b11decbc4b', { $expand: 'attributes' })
      .then(({ data }) => {
        setCollectionDefinition(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  useEffect(() => {
    if (user.role.role_id === 4) {
      navigate('/investor');
    }
    if (!collectionDefinition) {
      fetchCollectionDefinition();
    }
    fetchFromApi();
  }, [collectionDefinition, fetchCollectionDefinition, fetchFromApi, navigate, user.role.role_id]);

  const columns = [
    { field: 'id', headerName: 'ID', width: '50', flex: 0.15 },
    {
      field: 'form_name',
      headerName: 'Form',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.7,
    },
    {
      field: 'submission_date',
      headerName: '',
      flex: 0.7,
      renderCell: (params) => (
        <MKBox display="flex" alignItems="center" width="100%" height="100%">
          {datetimeFormatter(params.value, 'DD-MM-YYYY')}
        </MKBox>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.7,
      renderCell: (params) => {
        const status = params.row.status === 'Approved' || params.row.status === 'Pending' ? 1 : 0;
        return (
          <Button
            variant="contained"
            size="small"
            color="secondary"
            onClick={() => { navigate(`/fund-manager-form/edit?formId=${params.row.id}`, { state: { form: params.row, editable: true } }); }}
          >
            <MKTypography variant="body1" fontWeight="bold" fontSize="14px" color="white">
              {status ? 'View' : 'Edit'}
            </MKTypography>
          </Button>
        );
      },
    },
  ];

  const rows = useMemo(() => (rowsData || []).map((row) => {
    const { json_short_data } = row;
    const dynamicData = getDynamicData(row, collectionDefinition?.attributes);
    const jso = JSON.parse(json_short_data);
    const fundsData = JSON.parse(jso['6038803c-4b0b-460b-bbb9-89a584ae61b5']);
    dynamicData.funds = fundsData;
    dynamicData.id = row.id;
    dynamicData.form_name = `Online Form - ${user.username}`;
    dynamicData.submission_date = row.createddate;
    return dynamicData;
  }), [collectionDefinition?.attributes, rowsData, user?.username]);

  const rowsCount = rows.length;

  return (
    <MKBox mt={2}>
      {rowsCount === 0 && (
      <MKBox display="flex" flexDirection="row" alignItems="center">
        <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black" mr={2}>
          No submitted forms
        </MKTypography>
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => { navigate('/fund-manager-form'); }}
        >
          <MKTypography variant="body1" fontWeight="bold" fontSize="14px" color="white">
            Submit Form
          </MKTypography>
        </Button>
      </MKBox>
      )}
      {rowsCount > 0 && (
        <>
          <MKTypography mb={2} variant="body1" fontWeight="bold" fontSize="18px" color="black">
            Submitted Online Form
          </MKTypography>
          <MKBox>
            <BasicTable rows={rows} columns={columns} />
          </MKBox>
        </>
      )}
    </MKBox>
  );
};

export default PurpleSunSubmittedForms;
