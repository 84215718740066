import qs from 'qs';
import pick from 'lodash/pick';
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';
import { Stack } from '@mui/material';
import Divider from 'assets/theme/components/divider';
import MKTypography from 'components/MaterialKit/MKTypography';

const MenuModal = ({ menuItems, isOpen, onClose }) => {
  const location = useLocation();
  const queryParams = qs.parse((location.search || '').slice(1));

  if (!isOpen) return null;

  return (
    <MKBox
      display="flex"
      flexDirection="column"
      marginTop="0.5rem"
      sx={{
        backgroundColor: '#FFFFFF',
        borderRadius: '10px',
        boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.1)',
        minWidth: '200px',
      }}
    >
      <Stack>
        {(menuItems || []).map((menuItem, idx) => {
          const { label: menuItemLabel, path, disabled } = menuItem;
          return !disabled ? (
            <Button
              to={`${path}?${qs.stringify(pick(queryParams, 'mode'))}`}
              variant="text"
              color="#000"
              fontSize={16}
              size="small"
              onClick={() => onClose(false)}
              sx={{alignItems: 'center', justifyContent: 'flex-start', padding: '0.5rem 1rem' }}
            >
              <MKTypography variant="body1" color="black" fontSize="16px" fontWeight="bold">
                {menuItemLabel}
              </MKTypography>
            </Button>
          ) : null;
        })}
      </Stack>
    </MKBox>
  );
};

MenuModal.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  isOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  onClose: PropTypes.func,
};

export default MenuModal;
