import React, { useCallback, useState, useEffect, useMemo } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { Divider, Grid } from '@mui/material';
// eslint-disable-next-line import/no-cycle
import { getDynamicData } from 'utils/sections';
import { getCollectionDefinition } from 'api/collection_definitions';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { getDynamicTableRows } from 'api/sections';

const contactRequestCollectionDefinitionId = 'a18096ba-a8bf-4ced-a971-3fef7a9ec0c7';

const PurpleSunAllocatorInfo = ({ ...props }) => {
  const [searchParams] = useSearchParams();
  const allocatorUsername = searchParams.get('id');
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState(null);
  const { auth, setAuth } = useAuth();
  const user = auth?.user;
  const [userDetailsCollectionDefinition, setUserDetailsCollectionDefinition] = useState({});
  const [hasPermission, setHasPermission] = useState(false);

  const fetchCollectionDefinitions = useCallback(() => {
    return getCollectionDefinition('5970597b-affd-47c1-bdb4-7014dca4756b', { $expand: 'attributes' })
      .then(({ data }) => {
        setUserDetailsCollectionDefinition(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const fetchUserDetailsFromApi = useCallback(() => {
    if (!allocatorUsername) return Promise.resolve();
    return getDynamicTableRows('5970597b-affd-47c1-bdb4-7014dca4756b', { 'created_by[eq]': allocatorUsername })
      .then(({ data }) => {
        const humanReadableData = data.map((row) => {
          return getDynamicData(row, userDetailsCollectionDefinition.attributes);
        });
        setUserDetails(humanReadableData);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth, allocatorUsername, userDetailsCollectionDefinition.attributes]);

  useEffect(() => {
    if (user.role.role_id === 4) {
      navigate('/investor');
    }
    if (!userDetailsCollectionDefinition.collection_definition_id) {
      fetchCollectionDefinitions();
    }
    fetchUserDetailsFromApi();
  }, [fetchCollectionDefinitions, fetchUserDetailsFromApi, userDetailsCollectionDefinition.collection_definition_id]);

  const userInfo = useMemo(() => userDetails?.[0], [userDetails]);

  const checkIfContactRequestApproved = useCallback(() => {
    return getDynamicTableRows(contactRequestCollectionDefinitionId, { 'created_by[eq]': allocatorUsername })
      .then(({ data }) => {
        const permissions = data.map((row) => {
          const rowDetails = JSON.parse(row.json_short_data);
          return rowDetails;
        }).filter((row) => row['dbd919ef-603f-463d-89ee-3849b71449f3'] === user.email);
        if (permissions.length > 0) {
          setHasPermission(permissions[0]['49e04031-9c8a-4eb6-9ccf-dead390afd58'] !== 'false');
        }
      });
  }, [allocatorUsername, user.email]);

  useEffect(() => {
    checkIfContactRequestApproved();
  }, [checkIfContactRequestApproved]);

  const userRole = user?.role.role_id;

  return (
    <MKBox mt={2} mb={4} display="flex" flexDirection="column" {...props}>
      <MKBox display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <MKTypography variant="h1" fontSize="24px" color="black">
          {`Accepted ${userInfo?.first_name}  ${userInfo?.last_name}`}
          &apos;s Info
        </MKTypography>
      </MKBox>
      {
        /* userRole === 4 && */
       hasPermission && (
       <MKBox
         mt={2}
         display="flex"
         flexDirection="row"
         justifyContent="space-between"
         alignItems="left"
       >
         <Grid container spacing={1}>
           <Grid item xs={12} sm={12} md={4} lg={4}>
             <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
               {user.display_name}
             </MKTypography>
           </Grid>
           <Grid item xs={12} sm={12} md={4} lg={4}>
             <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
               Tel:&nbsp;
               {userInfo?.mobile}
             </MKTypography>
           </Grid>
           <Grid item xs={12} sm={12} md={4} lg={4}>
             <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
               Email:&nbsp;
               {userInfo?.email}
             </MKTypography>
           </Grid>
         </Grid>
       </MKBox>
       )
}
      <Divider />
      <MKBox
        display="flex"
        flexDirection="column"
        alignItems="left"
      >
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <MKTypography variant="body1" fontWeight="bold" fontSize="16px" color="black">
              {`${userInfo?.first_name}  ${userInfo?.last_name} Overview`}
            </MKTypography>
          </Grid>
          <Grid item xs={8}>
            <MKTypography variant="body1" fontSize="16px" color="black">
              Lorem ipsum dolor sit amet, consectetuer adipiscing
            </MKTypography>
            <MKTypography variant="body1" fontSize="16px" color="black">
              Lorem ipsum dolor sit amet, consectetuer adipiscing
            </MKTypography>
            <MKTypography variant="body1" fontSize="16px" color="black">
              Lorem ipsum dolor sit amet, consectetuer adipiscing
            </MKTypography>
            <MKTypography variant="body1" fontSize="16px" color="black">
              Lorem ipsum dolor sit amet, consectetuer adipiscing
            </MKTypography>
            <MKTypography variant="body1" fontSize="16px" color="black">
              Lorem ipsum dolor sit amet, consectetuer adipiscing
            </MKTypography>
            <MKTypography variant="body1" fontSize="16px" color="black">
              Lorem ipsum dolor sit amet, consectetuer adipiscing
            </MKTypography>
            <MKTypography variant="body1" fontSize="16px" color="black">
              Lorem ipsum dolor sit amet, consectetuer adipiscing
            </MKTypography>
          </Grid>
        </Grid>
      </MKBox>
    </MKBox>
  );
};

export default PurpleSunAllocatorInfo;
