import React, { useCallback } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { login } from 'api/auth';
import { getStoredAuth } from 'api';
import { useAuth } from 'contexts/auth';
import { useSearchParams, useNavigate } from 'react-router-dom';
import PSLoginForm from './loginForm';

const loginFormInitialValues = {
  username: '',
  password: '',
};

const PurpleSunLoginPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const onLoginToApi = useCallback((values, { setFieldError }) => {
    return login(values.username, values.password)
      .then(({ data: user }) => {
        const newAuth = { ...getStoredAuth(), user };
        setAuth(newAuth);
        const role = user.role.role_id;
        if (searchParams.get('redirect')) {
          navigate(searchParams.get('redirect'));
        } else {
          if (role === 3) {
            navigate('/fund_manager/forms');
          }
          if (role === 4) {
            navigate('/investor');
          }
          if (role === 1 || role === 2) {
            navigate('/staff');
          }
        }
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [searchParams, navigate, setAuth]);

  return (
    <MKBox display="flex" justifyContent="center" alignItems="center" width="100%">
      <MKBox mt="20vh" py={2} px={4} borderRadius="16px" sx={{ 'box-shadow': 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;' }} width="50%" justifyContent="center">
        <MKTypography variant="h1" py={1} color="black" textAlign="start" fontSize="24px">
          Log In
        </MKTypography>
        <PSLoginForm
          onLogin={onLoginToApi}
          initialValues={loginFormInitialValues}
          enableShowPassword
        />
      </MKBox>
    </MKBox>
  );
};

export default PurpleSunLoginPage;
