import PropTypes from 'prop-types';
import { useState } from 'react';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { purpleSunColor } from 'sections/PurpleSunMenu';

const LoginForm = ({ onLogin,
  initialValues,
  enableRememberMeOption,
  enableShowPassword,
  buttonColor,
  buttonTitle,
  usernamePlaceholder,
  passwordPlaceholder,
  usernameLabel,
  passwordLabel,
  showSignUp,
  showForgotPassword,
  textColor,
  forgotPasswordUrl,
  registerUrl,
  ...props }) => {
  const [rememberMe, setRememberMe] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onLogin}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <MKBox mb={2}>
              <MKInput
                name="username"
                label={usernameLabel || 'Username'}
                placeholder={usernamePlaceholder || 'Username'}
                value={values.username || ''}
                onChange={handleChange('username')}
                onBlur={handleBlur('username')}
                fullWidth
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                name="password"
                label={passwordLabel || 'Password'}
                placeholder={passwordPlaceholder || 'Password'}
                type={showPassword ? 'text' : 'password'}
                value={values.password || ''}
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                fullWidth
                InputProps={{
                  endAdornment: enableShowPassword ? (
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  ) : null,
                }}
              />
            </MKBox>
            {enableRememberMeOption && (
              <MKBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{ cursor: 'pointer', userSelect: 'none', ml: -1 }}
                >
                  &nbsp;&nbsp;Remember me
                </MKTypography>
              </MKBox>
            )}
            {!!errors.form && (
              <MKTypography variant="caption" color="error">{errors.form}</MKTypography>
            )}
            <MKBox mt={4} mb={3}>
              <Button type="submit" variant="contained" color={purpleSunColor} size="large" fullWidth disabled={isSubmitting}>
                Login
              </Button>
            </MKBox>
            <MKBox textAlign="center">
              <MKTypography variant="button" color={textColor || 'text'}>
                <MKTypography
                  component={Link}
                  to="/forgot"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                >
                  Forgot Password?
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        );
      }}
    </Formik>
  );
};

LoginForm.propTypes = {
  onLogin: PropTypes.func,
  initialValues: PropTypes.object,
  enableShowPassword: PropTypes.bool,
  enableRememberMeOption: PropTypes.bool,
  showSignUp: PropTypes.bool,
  showForgotPassword: PropTypes.bool,
  usernamePlaceholder: PropTypes.string,
  usernameLabel: PropTypes.string,
  passwordPlaceholder: PropTypes.string,
  passwordLabel: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonTitle: PropTypes.string,
  textColor: PropTypes.string,
  forgotPasswordUrl: PropTypes.string,
  registerUrl: PropTypes.string,
};

LoginForm.defaultProps = {
  onLogin: () => {},
  initialValues: {},
  enableShowPassword: false,
  enableRememberMeOption: false,
  showSignUp: true,
  showForgotPassword: false,
};

export default LoginForm;
