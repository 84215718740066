import React from 'react';
import MKTypography from 'components/MaterialKit/MKTypography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import MKBox from 'components/MaterialKit/MKBox';
import PropTypes from 'prop-types';

const FilterOpenButton = ({ label, count, onClick }) => {
  return (
    <Badge max={100000000000} badgeContent={count} color="ps" sx={{ color: '#fff' }}>
      <Button color="ps" variant="outlined" size="small" onClick={onClick} endIcon={<KeyboardArrowDownIcon color="ps" />} sx={{ borderRadius: '24px' }}>
        <MKTypography variant="body1" color={count > 0 ? 'ps' : 'dark'} fontWeight="bold" fontSize="16px">
          { label }
        </MKTypography>
      </Button>
    </Badge>
  );
};

FilterOpenButton.propTypes = {
  label: PropTypes.string,
  count: PropTypes.number,
  onClick: PropTypes.func,
};

FilterOpenButton.defaultProps = {
  label: 'Filter',
};

export default FilterOpenButton;
