import PropTypes from 'prop-types';
import { Formik } from 'formik';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { purpleSunColor } from 'sections/PurpleSunMenu';

const ContactForm = ({ onSubmit,
  initialValues,
  ...props }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <MKBox mb={1}>
              <MKTypography variant="body2" fontSize="16px" fontWeight="bold" color="black">
                Name*
              </MKTypography>
              <MKInput
                name="name"
                label=""
                placeholder="Name"
                value={values.name || ''}
                onChange={handleChange('name')}
                onBlur={handleBlur('name')}
                fullWidth
              />
            </MKBox>
            <MKBox mb={1}>
              <MKTypography variant="body2" fontSize="16px" fontWeight="bold" color="black">
                Company
              </MKTypography>
              <MKInput
                name="company"
                label=""
                placeholder="Company"
                value={values.company || ''}
                onChange={handleChange('company')}
                onBlur={handleBlur('company')}
                fullWidth
              />
            </MKBox>
            <MKBox mb={1}>
              <MKTypography variant="body2" fontSize="16px" fontWeight="bold" color="black">
                Email*
              </MKTypography>
              <MKInput
                name="email"
                label=""
                placeholder="Email"
                value={values.email || ''}
                onChange={handleChange('email')}
                onBlur={handleBlur('email')}
                fullWidth
              />
            </MKBox>
            <MKBox mb={1}>
              <MKTypography variant="body2" fontSize="16px" fontWeight="bold" color="black">
                Question*
              </MKTypography>
              <MKInput
                name="question"
                label=""
                placeholder="Question"
                multiline
                maxRows={10}
                minRows={4}
                value={values.question || ''}
                onChange={handleChange('question')}
                onBlur={handleBlur('question')}
                fullWidth
              />
            </MKBox>
            {!!errors.form && (
              <MKTypography variant="caption" color="error">{errors.form}</MKTypography>
            )}
            <MKBox display="flex" mt={2} mb={1} justifyContent="end">
              <Button type="submit" variant="contained" color={purpleSunColor} size="large" disabled={isSubmitting}>
                Submit
              </Button>
            </MKBox>
          </MKBox>
        );
      }}
    </Formik>
  );
};

ContactForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object,
};

ContactForm.defaultProps = {
  onSubmit: () => {},
  initialValues: {},
};

export default ContactForm;
