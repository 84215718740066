import React from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import ContactForm from './form';

const contactFormInitialValues = {
  name: '',
  company: '',
  email: '',
  question: '',
};

const onSubmitToApi = (values, { setFieldError }) => {
  console.log('=== TODO === Implement contact form submission', values);
  window.location.reload();
};

const PurpleSunContactForm = ({ ...props }) => {
  return (
    <MKBox {...props}>
      <MKBox width="50vw" justifyContent="center">
        <ContactForm
          onSubmit={onSubmitToApi}
          initialValues={contactFormInitialValues}
        />
      </MKBox>
    </MKBox>
  );
};

export default PurpleSunContactForm;
