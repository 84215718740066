import React, { useCallback, useEffect } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import { useAuth } from 'contexts/auth';
import { getDynamicTableRow, updateDynamicTableRow } from 'api/sections';
import { getCollectionDefinition } from 'api/collection_definitions';
// eslint-disable-next-line import/no-cycle
import { getDynamicData, setDynamicData } from 'utils/sections';
import { Formik } from 'formik';
import MKButton from 'components/MaterialKit/MKButton';
import Grid from '@mui/material/Grid';
import TextInput from 'components/TextInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from '@mui/material/Button';

const PurpleSunFundManagerContactDetails = ({ ...props }) => {
  const { auth } = useAuth();
  const USER_DETAILS_STORAGE_ID = auth.user?.user_info_id;
  const USER_DETAILS_STORAGE_COLL_DEF_ID = '5970597b-affd-47c1-bdb4-7014dca4756b';

  const [fundManager, setFundManager] = React.useState(null);
  const [fundManagerAttributes, setFundManagerAttributes] = React.useState([]);
  const [isEdit, setIsEdit] = React.useState(false);

  const fetchCollectionDefinition = useCallback(() => {
    return getCollectionDefinition(USER_DETAILS_STORAGE_COLL_DEF_ID, { $expand: 'attributes' });
  }, []);

  const fetchFundManagerFromApi = useCallback((attributes) => {
    if (!USER_DETAILS_STORAGE_ID) {
      return Promise.resolve();
    }
    getDynamicTableRow(USER_DETAILS_STORAGE_COLL_DEF_ID, USER_DETAILS_STORAGE_ID, {})
      .then(({ data }) => {
        const dynamicData = getDynamicData(data, attributes);
        setFundManager(dynamicData);
      });
  }, [USER_DETAILS_STORAGE_ID]);

  const handleContactDetailsUpdate = useCallback((values) => {
    const map = setDynamicData(values, fundManagerAttributes);
    const body = {
      json_short_data: JSON.stringify(map),
    };
    return updateDynamicTableRow(USER_DETAILS_STORAGE_COLL_DEF_ID, USER_DETAILS_STORAGE_ID, body)
      .then(({ data }) => {
        const dynamicData = getDynamicData(data, fundManagerAttributes);
        setFundManager(dynamicData);
      });
  }, [USER_DETAILS_STORAGE_ID, fundManagerAttributes]);

  useEffect(() => {
    fetchCollectionDefinition()
      .then(({ data }) => {
        setFundManagerAttributes(data.attributes);
        fetchFundManagerFromApi(data.attributes);
      });
  }, [fetchCollectionDefinition, fetchFundManagerFromApi]);

  if (!auth.user) {
    return null;
  }

  if (!fundManager) {
    return null;
  }

  return (
    <MKBox
      {...props}
    >
      <MKBox
        display="flex"
        justifyContent="start"
        flexDirection="column"
        my={2}
      >
        <MKTypography variant="body1" fontSize="16px" color="black" align="left">
          Welcome,
          {' '}
          {fundManager?.first_name}
          {' '}
          {fundManager?.last_name}
        </MKTypography>
      </MKBox>
      <Formik
        initialValues={fundManager}
        onSubmit={(values) => {
          handleContactDetailsUpdate(values);
          setIsEdit(false);
        }}
      >
        {({ values, handleChange, handleSubmit, handleBlur, touched }) => {
          return (
            <MKBox width="100%" component="form" method="post" autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextInput
                    name="first_name"
                    label="First Name"
                    disabled={!isEdit}
                    value={values?.first_name}
                    onChange={handleChange('first_name')}
                    onBlur={handleBlur('first_name')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    name="last_name"
                    label="Last Name"
                    disabled={!isEdit}
                    value={values?.last_name}
                    onChange={handleChange('last_name')}
                    onBlur={handleBlur('last_name')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    name="company_name"
                    type="company_name"
                    disabled={!isEdit}
                    label="Company Name"
                    value={values?.company_name}
                    onChange={handleChange('company_name')}
                    onBlur={handleBlur('company_name')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextInput
                    name="job_title"
                    label="Job Title"
                    disabled={!isEdit}
                    value={values?.job_title}
                    onChange={handleChange('job_title')}
                    onBlur={handleBlur('job_title')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    name="email"
                    type="email"
                    label="Email Address"
                    disabled={!isEdit}
                    value={values?.email}
                    onChange={handleChange('email')}
                    onBlur={handleBlur('email')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextInput
                    name="mobile"
                    type="mobile"
                    label="Mobile"
                    disabled={!isEdit}
                    value={values?.mobile}
                    onChange={handleChange('mobile')}
                    onBlur={handleBlur('mobile')}
                  />
                </Grid>
              </Grid>
              <Grid container item justifyContent="center" spacing={1} xs={12} my={2}>
                <Grid item xs={isEdit ? 6 : 12}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    fullWidth
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    {isEdit
                      ? (
                        <MKTypography variant="body1" color="white" size="small" fontSize="12px" fontWeight="bold" align="center">
                          Cancel
                        </MKTypography>
                      )
                      : (
                        <MKTypography variant="body1" color="white" size="small" fontSize="12px" fontWeight="bold" align="center">
                          Edit Contact Details
                        </MKTypography>
                      )}
                  </Button>
                </Grid>
                {isEdit && (
                  <Grid item xs={6}>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={handleSubmit}
                      disabled={!isEdit}
                      type="submit"
                      fullWidth
                    >
                      <MKTypography variant="body1" color="white" size="small" fontSize="12px" fontWeight="bold" align="center">
                        Save Contact Details
                      </MKTypography>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </MKBox>
          );
        }}
      </Formik>
    </MKBox>
  );
};

export default PurpleSunFundManagerContactDetails;
