import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormControl, FormControlLabel, Radio, Grid, RadioGroup } from '@mui/material';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import InputField from 'components/InputField';
import { purpleSunColor } from 'sections/PurpleSunMenu';
import { aum_options, current_fund_in_market_options, employees_count_options, fund_size_options, gp_options, hocloc_options, sector_options, vc_aum_options, tc_rr_options, tc_er_options, tc_shpr_options, nofc_options, funds } from './options';

const FundManagerOnlineForm = ({ onAction,
  viewOnly,
  initialValues,
  ...props }) => {
  const name = 'current_fund_in_market';
  return (
    <Formik
      initialValues={initialValues || { ...initialValues, current_fund_in_market: false }}
      onSubmit={onAction}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit} sx={{}}>
            <FormControl>
              <Grid container spacing={1}>
                <Grid item container xs={12}>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography mb={2} variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Manager Overview
                      </MKTypography>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Manager headquarter Geographic Location
                      </MKTypography>
                      <InputField
                        name="hgl"
                        label=""
                        disabled={viewOnly}
                        value={values.hgl}
                        onChange={handleChange('hgl')}
                        onBlur={handleBlur('hgl')}
                        type="select"
                        options={hocloc_options}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Manager 2nd Geographic Location
                      </MKTypography>
                      <InputField
                        name="hgl2"
                        disabled={viewOnly}
                        label=""
                        value={values.hgl2}
                        onChange={handleChange('hgl2')}
                        onBlur={handleBlur('hgl2')}
                        type="select"
                        options={hocloc_options}
                        fullWidth
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Manager current AUM in total (USD)
                      </MKTypography>
                      <InputField
                        name="current_aum"
                        label=""
                        value={values.current_aum}
                        onChange={handleChange('current_aum')}
                        onBlur={handleBlur('current_aum')}
                        type="select"
                        options={aum_options}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Manager current Venture Capital AUM in total (USD)
                      </MKTypography>
                      <InputField
                        name="current_vc_aum"
                        label=""
                        value={values.current_vc_aum}
                        onChange={handleChange('current_vc_aum')}
                        onBlur={handleBlur('current_vc_aum')}
                        type="select"
                        options={vc_aum_options}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Manager Years of Operation (Current GP)
                      </MKTypography>
                      <InputField
                        name="current_gp"
                        label=""
                        value={values.current_gp}
                        onChange={handleChange('current_gp')}
                        onBlur={handleBlur('current_gp')}
                        type="select"
                        options={gp_options}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Manager Current Full-time employee number
                      </MKTypography>
                      <InputField
                        name="current_employee_number"
                        label=""
                        value={values.current_employee_number}
                        onChange={handleChange('current_employee_number')}
                        onBlur={handleBlur('current_employee_number')}
                        type="select"
                        options={employees_count_options}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Manager Current targeted Fund size in Market
                      </MKTypography>
                      <InputField
                        name="current_targeted_fund_size"
                        label=""
                        value={values.current_targeted_fund_size}
                        onChange={handleChange('current_targeted_fund_size')}
                        onBlur={handleBlur('current_targeted_fund_size')}
                        type="select"
                        options={fund_size_options}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography mb={2} variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Investment Strategies:
                      </MKTypography>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Geographic Location: Primary
                      </MKTypography>
                      <InputField
                        name="tgl_primary"
                        label=""
                        value={values.tgl_primary}
                        type="select"
                        options={hocloc_options}
                        onChange={handleChange('tgl_primary')}
                        onBlur={handleBlur('tgl_primary')}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Geographic Location: Secondary
                      </MKTypography>
                      <InputField
                        name="tgl_secondary"
                        label=""
                        value={values.tgl_secondary}
                        type="select"
                        options={hocloc_options}
                        onChange={handleChange('tgl_secondary')}
                        onBlur={handleBlur('tgl_secondary')}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Geographic Location: Third
                      </MKTypography>
                      <InputField
                        name="tgl_third"
                        label=""
                        value={values.tgl_third}
                        type="select"
                        options={hocloc_options}
                        onChange={handleChange('tgl_third')}
                        onBlur={handleBlur('tgl_third')}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Sector: Primary
                      </MKTypography>
                      <InputField
                        name="ts_primary"
                        label=""
                        value={values.ts_primary}
                        type="select"
                        options={sector_options}
                        onChange={handleChange('ts_primary')}
                        onBlur={handleBlur('ts_primary')}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Sector: Secondary
                      </MKTypography>
                      <InputField
                        name="ts_secondary"
                        label=""
                        value={values.ts_secondary}
                        type="select"
                        options={sector_options}
                        onChange={handleChange('ts_secondary')}
                        onBlur={handleBlur('ts_secondary')}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Sector: Third
                      </MKTypography>
                      <InputField
                        name="ts_third"
                        label=""
                        value={values.ts_third}
                        type="select"
                        options={sector_options}
                        onChange={handleChange('ts_third')}
                        onBlur={handleBlur('ts_third')}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography mb={2} variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Status of the company at Entry
                      </MKTypography>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Revenue Range
                      </MKTypography>
                      <InputField
                        name="trr"
                        label=""
                        value={values.trr}
                        type="select"
                        options={tc_rr_options}
                        onChange={handleChange('trr')}
                        onBlur={handleBlur('trr')}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted company Ebitda Range
                      </MKTypography>
                      <InputField
                        name="ter"
                        label=""
                        value={values.ter}
                        type="select"
                        options={tc_er_options}
                        onChange={handleChange('ter')}
                        onBlur={handleBlur('ter')}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        Targeted Share holding percentage Range
                      </MKTypography>
                      <InputField
                        name="shpr"
                        label=""
                        value={values.shpr}
                        type="select"
                        options={tc_shpr_options}
                        onChange={handleChange('shpr')}
                        onBlur={handleBlur('shpr')}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography mb={2} variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Manger Track Record @ Current GP:
                      </MKTypography>
                      <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                        No. of Funds closed:
                      </MKTypography>
                      <InputField
                        name="funds_count"
                        label=""
                        value={values.funds_count}
                        type="select"
                        options={nofc_options}
                        onChange={handleChange('funds_count')}
                        onBlur={handleBlur('funds_count')}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                  <Grid item xs={12}>
                    {values.funds_count && values.funds_count > 0 && (
                      funds.map((fund) => {
                        const fundId = fund.name;
                        if (values.funds_count < fund.value) {
                          return null;
                        }
                        return (
                          <MKBox key={fundId}>
                            <Grid mb={1} mt={2} container>
                              <Grid item xs={1} alignContent="center">
                                <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                                  {fund.label}
                                </MKTypography>
                              </Grid>
                              <Grid item xs={2} alignContent="center">
                                <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                                  Vintage
                                </MKTypography>
                              </Grid>
                              <Grid item xs={9} alignContent="center">
                                <InputField
                                  name={`${fundId}_vintage`}
                                  label=""
                                  value={values[`${fundId}_vintage`] || ''}
                                  onChange={handleChange(`${fundId}_vintage`)}
                                  onBlur={handleBlur(`${fundId}_vintage`)}
                                  fullWidth
                                  disabled={viewOnly}
                                />
                              </Grid>
                            </Grid>
                            <Grid mb={1} container>
                              <Grid item xs={1} alignContent="center" />
                              <Grid item xs={2} alignContent="center">
                                <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                                  Amount Invested
                                </MKTypography>
                              </Grid>
                              <Grid item xs={9} alignContent="center">
                                <InputField
                                  name={`${fundId}_ai`}
                                  label=""
                                  value={values[`${fundId}_ai`] || ''}
                                  onChange={handleChange(`${fundId}_ai`)}
                                  onBlur={handleBlur(`${fundId}_ai`)}
                                  fullWidth
                                  disabled={viewOnly}
                                />
                              </Grid>
                            </Grid>
                            <Grid mb={1} container>
                              <Grid item xs={1} alignContent="center" />
                              <Grid item xs={2} alignContent="center">
                                <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                                  Total Net IRR to Investors
                                </MKTypography>
                              </Grid>
                              <Grid item xs={9} alignContent="center">
                                <InputField
                                  name={`${fundId}_net_irr`}
                                  label=""
                                  value={values[`${fundId}_net_irr`] || ''}
                                  onChange={handleChange(`${fundId}_net_irr`)}
                                  onBlur={handleBlur(`${fundId}_net_irr`)}
                                  fullWidth
                                  disabled={viewOnly}
                                />
                              </Grid>
                            </Grid>
                            <Grid mb={1} container>
                              <Grid item xs={1} alignContent="center" />
                              <Grid item xs={2} alignContent="center">
                                <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                                  Total DPI
                                </MKTypography>
                              </Grid>
                              <Grid item xs={9} alignContent="center">
                                <InputField
                                  name={`${fundId}_dpi`}
                                  label=""
                                  value={values[`${fundId}_dpi`] || ''}
                                  onChange={handleChange(`${fundId}_dpi`)}
                                  onBlur={handleBlur(`${fundId}_dpi`)}
                                  fullWidth
                                  disabled={viewOnly}
                                />
                              </Grid>
                            </Grid>
                            <Grid mb={1} container>
                              <Grid item xs={1} alignContent="center" />
                              <Grid item xs={2} alignContent="center">
                                <MKTypography variant="body2" fontSize="16px" fontWeight="bold">
                                  Total TVPI
                                </MKTypography>
                              </Grid>
                              <Grid item xs={9} alignContent="center">
                                <InputField
                                  name={`${fundId}_tvpi`}
                                  label=""
                                  value={values[`${fundId}_tvpi`] || ''}
                                  onChange={handleChange(`${fundId}_tvpi`)}
                                  onBlur={handleBlur(`${fundId}_tvpi`)}
                                  fullWidth
                                  disabled={viewOnly}
                                />
                              </Grid>
                            </Grid>
                          </MKBox>
                        );
                      })
                    )}
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography mb={2} variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Current Fund in the market? Yes/No
                      </MKTypography>
                      <RadioGroup
                        name={name}
                        disabled={viewOnly}
                        value={values.selectedOption}
                        onChange={(event) => {
                          setFieldValue(name, event.currentTarget.value);
                        }}
                      >
                        <Grid container mb={2}>
                          <Grid item xs={6}>
                            <FormControlLabel value={1} control={<Radio disabled={viewOnly} />} label="Yes: Final Closing Time" />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControlLabel value={0} control={<Radio disabled={viewOnly} />} label="No: Next Fund Launch timeline" />
                          </Grid>
                        </Grid>
                      </RadioGroup>
                      <InputField
                        name="fund_in_market_time"
                        label=""
                        placeholder="Please Select"
                        value={values.fund_in_market_time}
                        type="select"
                        options={current_fund_in_market_options}
                        onChange={handleChange('fund_in_market_time')}
                        onBlur={handleBlur('fund_in_market_time')}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid item container xs={12} spacing={1}>
                  <Grid item xs={12}>
                    <MKBox mb={2}>
                      <MKTypography mb={2} variant="body1" fontWeight="bold" fontSize="18px" color="black">
                        Descriptions and Differentiation Comments
                      </MKTypography>
                      <InputField
                        name="description"
                        label=""
                        placeholder="Please Select"
                        value={values.description}
                        type="text"
                        multiline
                        minRows={4}
                        maxRows={8}
                        onChange={handleChange('description')}
                        onBlur={handleBlur('description')}
                        fullWidth
                        disabled={viewOnly}
                      />
                    </MKBox>
                  </Grid>
                </Grid>
                <Grid item container spacing={1} xs={12} justifyContent="end">
                  <Grid item xs={4}>
                    <MKBox mt={4} mb={4}>
                      <Button type="submit" variant="gradient" color={purpleSunColor} size="large" fullWidth>
                        Submit
                      </Button>
                    </MKBox>
                  </Grid>
                </Grid>
              </Grid>
              {!!errors.form && (
              <MKTypography variant="caption" color="error">{errors.form}</MKTypography>
              )}
            </FormControl>
          </MKBox>
        );
      }}
    </Formik>
  );
};

FundManagerOnlineForm.propTypes = {
  onAction: PropTypes.func,
  viewOnly: PropTypes.bool,
  initialValues: PropTypes.object,
};

FundManagerOnlineForm.defaultProps = {
  onAction: () => {},
  viewOnly: false,
  initialValues: {},
};

export default FundManagerOnlineForm;
