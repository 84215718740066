import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { purpleSunColor } from 'sections/PurpleSunMenu';

const LoginForm = ({ onLogin,
  initialValues,
  enableShowPassword,
  ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onLogin}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit} mt={2}>
            <MKBox mb={2}>
              <MKTypography variant="body2" fontSize="16px" fontWeight="bold" color="black">
                Email Address
              </MKTypography>
              <MKInput
                name="username"
                label=""
                placeholder="name@email.com"
                value={values.username || ''}
                onChange={handleChange('username')}
                onBlur={handleBlur('username')}
                fullWidth
              />
            </MKBox>
            <MKBox mb={2}>
              <MKTypography variant="body2" fontSize="16px" fontWeight="bold" color="black">
                Password
              </MKTypography>
              <MKInput
                name="password"
                label=""
                placeholder="********"
                value={values.password || ''}
                type={showPassword ? 'text' : 'password'}
                onChange={handleChange('password')}
                onBlur={handleBlur('password')}
                fullWidth
                InputProps={{
                  endAdornment: enableShowPassword ? (
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  ) : null,
                }}
              />
            </MKBox>
            {!!errors.form && (
              <MKTypography variant="caption" color="error">{errors.form}</MKTypography>
            )}
            <MKBox mt={4} mb={1}>
              <Button type="submit" variant="contained" color={purpleSunColor} size="large" fullWidth disabled={isSubmitting}>
                Login
              </Button>
            </MKBox>
            <MKBox textAlign="left">
              <MKTypography
                component={Link}
                to="/forgot"
                variant="button"
                color="black"
                fontWeight="medium"
              >
                Forgot Password?
              </MKTypography>
            </MKBox>
          </MKBox>
        );
      }}
    </Formik>
  );
};

LoginForm.propTypes = {
  onLogin: PropTypes.func,
  enableShowPassword: PropTypes.bool,
  initialValues: PropTypes.object,
};

LoginForm.defaultProps = {
  onLogin: () => {},
  enableShowPassword: true,
  initialValues: {},
};

export default LoginForm;
